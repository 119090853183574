import * as THREE from 'three';

export default {
  name: 'Ткань',
  material: {
    constructor: THREE.MeshStandardMaterial,
    specularIntensity: 0.35,
    roughness: 0.7,
    metalness: 0,
    textures: [],
  },
};
