import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import logo from 'assets/img/logo.svg';
import logoWhite from 'assets/img/logo-white.svg';

const Logo = ({ white }) => {
  return (
    <Link to="/" className={`logo ${white ? 'logo--white' : ''}`}>
      <img className="logo-grey" src={logo} alt="logo" />
      <img className="logo-white" src={logoWhite} alt="logo" />
    </Link>
  );
};

Logo.propTypes = {
  white: PropTypes.bool,
};

export default Logo;
