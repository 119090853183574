import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import geolocationIcon from 'assets/img/icon--geolocation.svg';
import phoneIcon from 'assets/img/icon--phone.svg';
import mailIcon from 'assets/img/icon--mail.svg';
import chevronRightIcon from 'assets/img/icon--chevron-right.svg';

const BranchesList = observer(({ list = [] }) => {
  const toggleList = ({ currentTarget }) => {
    currentTarget.classList.toggle('active');
    currentTarget.nextSibling.classList.toggle(
      'branches-list-item-row--active'
    );
  };

  return (
    <div className="branches-list">
      <div className="branches-list-overflow">
        {list.map((item, index) => (
          <div className="branches-list-item" key={index}>
            <h1>{item.attributes.name}</h1>
            <button
              onClick={toggleList}
              className={`
                ${item.attributes.contacts.length > 4 ? 'visible-desktop' : ' '}
                ${item.attributes.contacts.length > 2 ? 'visible-tablet' : ' '}
                ${item.attributes.contacts.length > 1 ? 'visible-mobile' : ' '}
              `}
            >
              <span className="active-hide">Показать все</span>
              <span className="active-show">Скрыть</span>
              <img src={chevronRightIcon} alt="chevron-icon" />
            </button>
            <div className="branches-list-item-row">
              {item.attributes.contacts.map((contact, i) => (
                <Contact data={contact} key={i} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

const Contact = ({ data }) => {
  return (
    <div className="branches-list-contact">
      <p className="branches-list-contact-name">{data.name}</p>
      <p className="branches-list-contact-description">{data.description}</p>

      {data.address.length > 0 && (
        <div className="branches-list-contact-block">
          <img src={geolocationIcon} alt="geolocation-icon" />
          <div className="branches-list-contact-block-values">
            {data.address.map((address, index) => (
              <span key={index}>{address.address}</span>
            ))}
          </div>
        </div>
      )}

      {data.phone.length > 0 && (
        <div className="branches-list-contact-block">
          <img src={phoneIcon} alt="phone-icon" />
          <div className="branches-list-contact-block-values">
            {data.phone.map((phone, index) => (
              <span key={index}>
                <a href={`tel: ${phone.number}`}>{phone.number}</a>
                {`${phone.addon?.length ? `, ${phone.addon}` : ''}`}
              </span>
            ))}
          </div>
        </div>
      )}

      {data.email.length > 0 && (
        <div className="branches-list-contact-block">
          <img src={mailIcon} alt="mail-icon" />
          <div className="branches-list-contact-block-values">
            {data.email.map((email, index) => (
              <span key={index}>{email.email}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Contact.propTypes = {
  data: PropTypes.object,
};

BranchesList.propTypes = {
  list: PropTypes.array,
};

export default BranchesList;
