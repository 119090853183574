import PropTypes from 'prop-types';

import usersIcon from 'assets/img/icon--users.svg';
import chevronIcon from 'assets/img/icon--chevron.svg';

const FilterSelect = ({
  options = [],
  placeholder = 'Не выбрано',
  value,
  onChange = () => {},
}) => {
  const handleChange = ({ target }) => {
    onChange(Number(target.value));
  };

  return (
    <label className="filters-select">
      <img
        className="filters-select-icon filters-select-icon--left"
        src={usersIcon}
        alt="users-icon"
      />

      <select value={value} onChange={handleChange}>
        <option value={-1}>{placeholder}</option>
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.title}
          </option>
        ))}
      </select>

      <img
        className="filters-select-icon filters-select-icon--end"
        src={chevronIcon}
        alt="chevron-icon"
      />
    </label>
  );
};

FilterSelect.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.number,
};

export default FilterSelect;
