import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getAssetUrl } from 'helpers';

import closeIcon from 'assets/img/icon--close.svg';
import pauseIcon from 'assets/img/icon--pause.svg';
import playIcon from 'assets/img/icon--play.svg';

const Animation = ({ url, name, onClose }) => {
  const [paused, setPausedState] = useState(true);

  const getVideo = () => document.querySelector('.animation-modal video');

  const playVideo = () => {
    const video = getVideo();
    video.play();
    setPausedState(false);
  };

  const pauseVideo = () => {
    const video = getVideo();
    video.pause();
    setPausedState(true);
  };

  const stopPropagation = (e) => e.stopPropagation();

  useEffect(playVideo, []);

  return (
    <div className="animation-modal" onClick={onClose}>
      <div className="animation-modal-content" onClick={stopPropagation}>
        <button className="animation-modal-close" onClick={onClose}>
          <img src={closeIcon} />
        </button>

        <video playsInline src={getAssetUrl(url)} loop={true} muted={true} />

        <div className="animation-modal-controls">
          {paused ? (
            <button
              className="animation-modal-controls-button"
              onClick={playVideo}
            >
              <img src={playIcon} />
            </button>
          ) : (
            <button
              className="animation-modal-controls-button"
              onClick={pauseVideo}
            >
              <img src={pauseIcon} />
            </button>
          )}
          <span>Механизм раскладки{name && `: ${name}`}</span>
        </div>
      </div>
    </div>
  );
};

Animation.propTypes = {
  onClose: PropTypes.func,
  name: PropTypes.string,
  url: PropTypes.string,
};

export default Animation;
