import PropTypes from 'prop-types';
import { useSwiper } from 'swiper/react';

import arrowIcon from 'assets/img/icon--swiper-arrow-left.svg';

const CatalogItemPreviewButton = ({ action, disabled }) => {
  const swiper = useSwiper();

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    swiper[action]();
  };

  if (disabled) return null;

  return (
    <button
      className={`swiper-navigation-button swiper-navigation-button--${action}`}
      onClick={onClick}
    >
      <img src={arrowIcon} />
    </button>
  );
};

CatalogItemPreviewButton.propTypes = {
  disabled: PropTypes.bool,
  action: PropTypes.string,
};

export default CatalogItemPreviewButton;
