import { API_ENDPOINT } from 'config';
import { Color } from 'three';

export const getHexString = (color) => {
  if (color instanceof Color) {
    return `#${color.getHexString()}`;
  }
  return `#${new Color(color).getHexString()}`;
};

export const getAssetUrl = (url) => {
  return `${API_ENDPOINT}${url}`;
};

export const getAssetUrlBySize = (data, size) => {
  if (!data) return null;

  const url =
    size.length && data.attributes.formats
      ? data.attributes.formats[size]?.url || data.attributes.url
      : data.attributes.url;

  return getAssetUrl(url);
};

export const onImageLoaded = ({ target }) => {
  if (!target.complete) return;
  target.parentElement.classList.add('loaded');
};

export const downloadFile = async (file) => {
  const respone = await fetch(getAssetUrl(file.attributes.url));
  const blob = await respone.blob();

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.download = file.attributes.name;
  link.type = file.attributes.mime;
  link.href = url;

  link.click();

  URL.revokeObjectURL(url);
};
