import { observer } from 'mobx-react';
import { useEffect } from 'react';
import apiStore from 'store/apiStore';

import DocumentsList from 'Components/DocumentsList';

const Advertisement = observer(() => {
  useEffect(() => {
    if (apiStore.advertisementsData.length > 0) return;
    apiStore.loadAdvertisements();
  }, []);

  return (
    <div className="advertisement-page">
      <div className="advertisement-page-overflow">
        <DocumentsList list={[...apiStore.advertisementsData]} />
      </div>
    </div>
  );
});

export default Advertisement;
