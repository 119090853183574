import PropTypes from 'prop-types';

const FilterOption = ({ title, subtitle, checked, onChange = () => {} }) => {
  const handleChange = ({ target }) => {
    onChange(target.checked);
  };

  return (
    <label className="filters-option">
      <input checked={checked} type="checkbox" onChange={handleChange} />
      <div className="filters-option-box" />
      <p>
        {title}
        <span>{subtitle}</span>
      </p>
    </label>
  );
};

FilterOption.propTypes = {
  subtitle: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  title: PropTypes.string,
};

export default FilterOption;
