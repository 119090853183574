import * as THREE from 'three';

class TextureLoader extends THREE.TextureLoader {
  fileLoader = new THREE.FileLoader();

  constructor(manager) {
    super(manager);

    THREE.Cache.enabled = true;
    this.fileLoader.setResponseType('blob');
  }

  async loadAsync(url, onProgress) {
    return new Promise((resolve, reject) => {
      const cachedImage = THREE.Cache.get(url);
      if (cachedImage) {
        this.load(url, resolve, () => {}, reject);
        return;
      }

      const onFileLoad = (blob) => {
        const objUrl = URL.createObjectURL(blob);
        const image = document.createElementNS(
          'http://www.w3.org/1999/xhtml',
          'img'
        );

        image.onload = () => {
          THREE.Cache.add(url, image);
          URL.revokeObjectURL(objUrl);
          this.load(url, resolve, () => {}, reject);
        };

        image.src = objUrl;
      };

      this.fileLoader.load(url, onFileLoad, onProgress, reject);
    });
  }
}

export default TextureLoader;
