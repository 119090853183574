import { downloadFile, getAssetUrl } from 'helpers';
import PropTypes from 'prop-types';

import downloadIconLight from 'assets/img/icon--download-2.svg';
import downloadIconDark from 'assets/img/icon--download-1.svg';

const DocumentsList = ({ list }) => {
  return (
    <div className="documents-list">
      {list.map((item, index) => {
        const hasPreview = !!item.attributes.previewUrl;

        return (
          <button
            onClick={() => downloadFile(item)}
            className={`documents-list-item ${
              hasPreview ? 'documents-list-item--with-preview' : ''
            }`}
            key={index}
          >
            {hasPreview && (
              <>
                <img
                  className="documents-list-item__preview"
                  src={getAssetUrl(item.attributes.previewUrl)}
                  alt="preview"
                />
                <div className="documents-list-item__shade" />
              </>
            )}
            <img
              className="documents-list-item__icon"
              src={hasPreview ? downloadIconLight : downloadIconDark}
              alt="download-icon"
            />
            <span className="documents-list-item__title">
              {item.attributes.name}
            </span>
          </button>
        );
      })}
    </div>
  );
};

DocumentsList.propTypes = {
  list: PropTypes.array,
};

export default DocumentsList;
