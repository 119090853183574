import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { getAssetUrl } from 'helpers';
import apiStore from 'store/apiStore';

import DocumentsList from 'Components/DocumentsList';

const Fabrics = observer(() => {
  useEffect(() => {
    if (apiStore.fabricsData.length === 0) {
      apiStore.loadFabrics();
    }

    if (apiStore.suppliersData.length === 0) {
      apiStore.loadSuppliers();
    }
  }, []);

  return (
    <div className="fabrics-page">
      <div className="fabrics-page-overflow">
        <h1>Каталог тканей</h1>
        <DocumentsList list={[...apiStore.fabricsData]} />

        <h1>Поставщики тканей</h1>
        <div className="suppliers-list">
          {apiStore.suppliersData.map((item, index) => (
            <div className="suppliers-list-item" key={index}>
              <img
                src={getAssetUrl(item.attributes.logo.data.attributes.url)}
              />

              <span>{item.attributes.name}</span>

              <a href={item.attributes.link} target="_blank" rel="noreferrer">
                {item.attributes.link
                  .replace('https://', '')
                  .replace('http://', '')}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Fabrics;
