import { observer } from 'mobx-react';
import { useEffect } from 'react';
import apiStore from 'store/apiStore';

import DocumentsList from 'Components/DocumentsList';

const Declarations = observer(() => {
  useEffect(() => {
    if (apiStore.declarationsData.length > 0) return;
    apiStore.loadDeclarations();
  }, []);

  return (
    <div className="declarations-page">
      <div className="declarations-page-overflow">
        <DocumentsList list={[...apiStore.declarationsData]} />
      </div>
    </div>
  );
});

export default Declarations;
