import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { deviceMedia } from 'enums/device';
import { getAssetUrl } from 'helpers';
import apiStore from 'store/apiStore';

const Background = observer(({ children }) => {
  return (
    <Swiper
      className="background"
      modules={[Autoplay, Pagination]}
      slidesPerView={1}
      autoplay={{ delay: 10000, disableOnInteraction: false }}
      speed={750}
      pagination={{ clickable: true, enabled: apiStore.banners.length > 1 }}
      allowTouchMove={false}
    >
      {apiStore.banners.map((banner, index) => (
        <SwiperSlide className="background-slide" key={index}>
          <div className="background-slide-text">
            <h1>{banner.title}</h1>
            <h2>{banner.subtitle}</h2>
            <p>{banner.description}</p>
          </div>
          <picture>
            <source
              srcSet={getAssetUrl(banner.mobile.data.attributes.url)}
              media={deviceMedia.mobile}
            />
            <source
              srcSet={getAssetUrl(banner.tablet.data.attributes.url)}
              media={deviceMedia.tablet}
            />
            <img
              src={getAssetUrl(banner.desktop.data.attributes.url)}
              alt="background"
            />
          </picture>
        </SwiperSlide>
      ))}
      {children}
    </Swiper>
  );
});

export default Background;
