import { useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import apiStore from 'store/apiStore';
import { getAssetUrl } from 'helpers';
import { Swiper, SwiperSlide } from 'swiper/react';

import RecommendationsList from 'Components/RecommendationsList';
import Background from 'Components/Background';
import Searchbar from 'Components/Searchbar';
import Copyright from 'Components/Copyright';
import Logo from 'Components/Logo';

import allIcon from 'assets/img/icon--all.svg';
import hitsPad from 'assets/img/hits-pad--transparent.svg';
import hitsPadMobile from 'assets/img/hits-pad--transparent--mobile.svg';
import categoriesPad from 'assets/img/categories-pad.svg';
import repairIcon from 'assets/img/icon--repair-1.svg';
import gridIcon from 'assets/img/icon--grid.svg';
import arrowIcon from 'assets/img/icon--arrow-right.svg';
import infoIcon from 'assets/img/icon--info.svg';

const Home = observer(() => {
  return (
    <section className="home-page">
      <Logo white={false} />

      <div className="navigation">
        <Searchbar />

        <Link to="/about" className="navigation-link">
          <img src={infoIcon} alt="info-icon" />
          <span>Информация</span>
        </Link>
      </div>

      <Background>
        <div className="content">
          <div className="categories">
            <img className="categories-pad" src={categoriesPad} />
            <div className="categories-link-list-tabs">
              <button className="categories-link-list-tab">
                <img src={gridIcon} />
                <span>Наши товары</span>
              </button>
            </div>
            <CategoriesSwiper />
            <button className="categories-link-wrapper">
              <Link to="/catalog?glb=1" className="categories-link">
                <img src={repairIcon} />
                <span>
                  Конструктор <br /> диванов
                </span>
              </Link>
            </button>
          </div>

          <RecommendationsList pad={hitsPad} mobilePad={hitsPadMobile} />
        </div>
      </Background>
      <Copyright />
    </section>
  );
});

const CategoriesSwiper = observer(() => {
  const [swiper, setSwiper] = useState(null);

  const onArrowClick = () => {
    if (swiper.isEnd) {
      swiper.slideTo(0);
      return;
    }
    swiper.slideNext();
  };

  const onLock = (data) => {
    data.el.parentElement.classList.remove('categories-link-list--show-arrow');
  };

  const onUnLock = (data) => {
    data.el.parentElement.classList.add('categories-link-list--show-arrow');
  };

  return (
    <div className="categories-link-list">
      <Swiper
        slidesPerView={'auto'}
        grabCursor={true}
        threshold={5}
        onSwiper={(data) => setSwiper(data)}
        onLock={onLock}
        onUnlock={onUnLock}
      >
        <SwiperSlide>
          <Link to="/catalog" className="categories-link">
            <img src={allIcon} />
            <span>
              Все <br /> товары
            </span>
          </Link>
        </SwiperSlide>
        {apiStore.categories.map((category, index) => (
          <SwiperSlide key={index}>
            <Link
              to={`/catalog?categories=${category.id}`}
              className="categories-link"
            >
              <img
                src={getAssetUrl(category.attributes.icon.data.attributes.url)}
              />
              <span>{category.attributes.name}</span>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <button className="swiper-button-next" onClick={onArrowClick}>
        <img src={arrowIcon} />
      </button>
    </div>
  );
});

export default Home;
