import * as THREE from 'three';

import roughness from 'assets/textures/wood/roughness.png';
import normal from 'assets/textures/wood/normal.png';

export default {
  name: 'Дерево',
  material: {
    constructor: THREE.MeshPhysicalMaterial,
    specularIntensity: 1,
    roughness: 0.7,
    metalness: 0,
    textures: [
      {
        key: 'roughnessMap',
        url: roughness,
        repeat: [7, 7],
      },
      {
        key: 'normalMap',
        url: normal,
        repeat: [7, 7],
      },
    ],
  },
};
