import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import apiStore from 'store/apiStore';

import Searchbar from 'Components/Searchbar';

import arrowIcon from 'assets/img/icon--chevron-left.svg';
import houseIcon from 'assets/img/icon--house.svg';
import closeIcon from 'assets/img/icon--close.svg';

const CatalogCategories = observer(() => {
  const [toggleable, setToggleableState] = useState(false);
  const [open, setOpenState] = useState(false);

  const { chosenCategories } = apiStore;

  const chosenAll = apiStore.categories.every((category) => {
    return chosenCategories.some((id) => category.id === id);
  });

  const addCategoryId = (categoryId) => {
    const ids = [...chosenCategories, categoryId];
    apiStore.setChosenCategories(ids);

    apiStore.setCurrentPage(1);
    apiStore.loadFilteredFurnitures();
    apiStore.updateSearchParams();
    setTimeout(() => onResize(), 100);
  };

  const removeCategoryId = (categoryId) => {
    const ids = chosenCategories.filter((id) => id !== categoryId);
    apiStore.setChosenCategories(ids);

    apiStore.setCurrentPage(1);
    apiStore.loadFilteredFurnitures();
    apiStore.updateSearchParams();
    setTimeout(() => onResize(), 100);
  };

  const toggleAll = () => {
    if (chosenAll) {
      apiStore.setChosenCategories([]);
    } else {
      apiStore.setChosenCategories(apiStore.categories.map(({ id }) => id));
    }

    apiStore.loadFilteredFurnitures();
    apiStore.updateSearchParams();
    setTimeout(() => onResize(), 100);
  };

  const getActiveState = (categoryId) => {
    return chosenCategories.some((id) => id === categoryId);
  };

  const getCategoryItemClassName = (active) => {
    return `catalog-categories-item ${
      active ? 'catalog-categories-item--active' : ''
    }`;
  };

  const getCategoryItem = (category, index) => {
    const active = getActiveState(category.id);
    const onClick = active
      ? () => removeCategoryId(category.id)
      : () => addCategoryId(category.id);

    return (
      <button
        className={getCategoryItemClassName(active)}
        onClick={onClick}
        key={index}
      >
        {category.attributes.name}
        <img src={closeIcon} />
      </button>
    );
  };

  const onResize = () => {
    const wrapper = document.querySelector('.catalog-categories-list-wrapper');
    const list = document.querySelector('.catalog-categories-list');

    const { height: wrapperHeight } = wrapper.getBoundingClientRect();
    const { height: listHeight } = list.getBoundingClientRect();

    if (Math.floor(listHeight) > Math.floor(wrapperHeight)) {
      setToggleableState(true);
      return;
    }
    setToggleableState(false);
  };

  const onSubmitSearch = () => {
    apiStore.setCurrentPage(1);
    apiStore.loadFilteredFurnitures();
    apiStore.updateSearchParams();
  };

  useEffect(() => {
    const container = document.querySelector(
      '.catalog-categories-list-wrapper'
    );

    const observer = new ResizeObserver(onResize);
    observer.observe(container);

    onResize();

    return () => observer.disconnect();
  }, [apiStore.categories, open]);

  return (
    <div
      className={`catalog-categories ${
        open ? 'catalog-categories--active' : ''
      } ${toggleable ? 'catalog-categories--toggleable' : ''}`}
    >
      <div className="catalog-categories-list-wrapper">
        <div className="catalog-categories-list">
          <button
            className={getCategoryItemClassName(chosenAll)}
            onClick={toggleAll}
          >
            Все товары
            <img src={closeIcon} />
          </button>
          {apiStore.categories.map(getCategoryItem)}
        </div>
      </div>

      <div className="catalog-categories-buttons">
        <button
          className="catalog-categories-toggle"
          onClick={() => setOpenState(!open)}
        >
          {open ? 'Скрыть' : 'Показать еще'}
          <img src={arrowIcon} alt="arrow-icon" />
        </button>

        <Searchbar onSubmit={onSubmitSearch} />

        <Link className="catalog-categories-home" to="/">
          <img src={houseIcon} alt="house-icon" />
        </Link>
      </div>
    </div>
  );
});

export default CatalogCategories;
