import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import apiStore from 'store/apiStore';

import magnifyIcon from 'assets/img/icon--magnify.svg';

const Searchbar = observer(({ onSubmit = () => {} }) => {
  const [searchState, setSearchState] = useState(false);
  const navigate = useNavigate();

  const onSubmitSearch = (event) => {
    event.preventDefault();
    navigate(`/catalog?name=${apiStore.filters.name}`);
    onSubmit();
  };

  const onCloseSearch = () => {
    setSearchState(false);
    document.querySelector('.searchbar input').blur();
  };

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (key !== 'Escape') return;
      onCloseSearch();
    };

    const onClick = (event) => {
      if (event.target.closest('.searchbar')) return;
      onCloseSearch();
    };

    document.addEventListener('keydown', onKeyDown);
    window.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('click', onClick);
      apiStore.setStringOptionData('name', '');
    };
  }, []);

  return (
    <form
      onSubmit={onSubmitSearch}
      className={`searchbar ${searchState ? 'searchbar--active' : ''}`}
    >
      <input
        type="text"
        value={apiStore.filters.name}
        onFocus={() => setSearchState(true)}
        onChange={({ target }) =>
          apiStore.setStringOptionData('name', target.value)
        }
        placeholder="Поиск"
      />
      <button type="submit" onClick={onSubmitSearch}>
        <img src={magnifyIcon} alt="magnify-icon" />
      </button>
    </form>
  );
});

Searchbar.propTypes = {
  onSubmit: PropTypes.func,
};

export default Searchbar;
