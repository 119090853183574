import chenille from 'enums/materials/chenille';
import leather from 'enums/materials/leather';
import matting from 'enums/materials/matting';
import plastic from 'enums/materials/plastic';
import velours from 'enums/materials/velours';
import textile from 'enums/materials/textile';
import metal from 'enums/materials/metal';
import seude from 'enums/materials/seude';
import wood from 'enums/materials/wood';

export const materials = [
  chenille,
  textile,
  leather,
  matting,
  plastic,
  velours,
  metal,
  seude,
  wood,
];

export const selectableMaterials = [leather, seude, velours, chenille, matting];

export const getMaterialByName = (name) => {
  return materials.find((material) => material.name === name);
};
