import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { pdfjs, Document, Page } from 'react-pdf';
import { downloadFile, getAssetUrl } from 'helpers';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import downloadIcon from 'assets/img/icon--download.svg';
import closeIcon from 'assets/img/icon--close.svg';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const PDFModal = observer(({ file, onClose = () => {} }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: pages }) => {
    setNumPages(pages);
  };

  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (key !== 'Escape') return;
      onClose();
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onkeydown);
  }, []);

  const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
  };

  return (
    <div className="pdf-modal" onClick={onClose}>
      <div className="pdf-modal-content" onClick={stopPropagation}>
        <button className="pdf-modal-close" onClick={onClose}>
          <img src={closeIcon} />
        </button>

        <button
          className="pdf-modal-download"
          onClick={() => downloadFile(file)}
        >
          <img src={downloadIcon} />
        </button>

        <Document
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          file={getAssetUrl(file.attributes.url)}
          loading={''}
        >
          {Array.from(Array(numPages).keys()).map((key) => (
            <Page key={key} pageNumber={key + 1} />
          ))}
        </Document>
      </div>
    </div>
  );
});

PDFModal.propTypes = {
  onClose: PropTypes.func,
  file: PropTypes.object,
};

export default PDFModal;
