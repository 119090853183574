import * as THREE from 'three';

import preview from 'assets/textures/seude/preview.jpg';
import roughness from 'assets/textures/seude/roughness.png';
import normal from 'assets/textures/seude/normal.png';

export default {
  preview,
  name: 'Замша',
  material: {
    constructor: THREE.MeshPhysicalMaterial,
    specularIntensity: 0.25,
    roughness: 0.8,
    metalness: 0,
    textures: [
      {
        key: 'roughnessMap',
        url: roughness,
        repeat: [15, 15],
      },
      {
        key: 'normalMap',
        url: normal,
        repeat: [15, 15],
      },
    ],
  },
};
