import wall0 from 'assets/presets/wall-0.png';
import wall1 from 'assets/presets/wall-1.png';
import wall2 from 'assets/presets/wall-2.png';
import floor0 from 'assets/presets/floor-0.png';
import floor1 from 'assets/presets/floor-1.png';
import floor2 from 'assets/presets/floor-2.png';
import curtain0 from 'assets/presets/curtain-0.png';
import curtain1 from 'assets/presets/curtain-1.png';
import curtain2 from 'assets/presets/curtain-2.png';
import curtain3 from 'assets/presets/curtain-3.png';
import curtain4 from 'assets/presets/curtain-4.png';
import curtain5 from 'assets/presets/curtain-5.png';
import curtain6 from 'assets/presets/curtain-6.png';
import curtain7 from 'assets/presets/curtain-7.png';
import curtain8 from 'assets/presets/curtain-8.png';
import curtain9 from 'assets/presets/curtain-9.png';

export const walls = [
  {
    name: 'Синий',
    img: wall0,
  },
  {
    name: 'Серый',
    img: wall1,
  },
  {
    name: 'Мятный',
    img: wall2,
  },
];

export const floors = [
  {
    name: 'Дуб',
    img: floor0,
  },
  {
    name: 'Сосна',
    img: floor1,
  },
  {
    name: 'Мрамор',
    img: floor2,
  },
];

export const curtains = [
  {
    name: '',
    color: '#ad9595',
    img: curtain0,
  },
  {
    name: '',
    color: '#afa093',
    img: curtain1,
  },
  {
    name: '',
    color: '#a7abae',
    img: curtain2,
  },
  {
    name: '',
    color: '#9a7c86',
    img: curtain3,
  },
  {
    name: '',
    color: '#581d4e',
    img: curtain4,
  },
  {
    name: '',
    color: '#a18341',
    img: curtain5,
  },
  {
    name: '',
    color: '#174a45',
    img: curtain6,
  },
  {
    name: '',
    color: '#5d848a',
    img: curtain7,
  },
  {
    name: '',
    color: '#273e5d',
    img: curtain8,
  },
  {
    name: '',
    color: '#455468',
    img: curtain9,
  },
];
