import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getAssetUrl } from 'helpers';

import CatalogItemPreview from 'Components/CatalogItemPreview';
import CatalogItemDimensions from 'Components/CatalogItemDimensions';

const CatalogItem = ({ data }) => {
  const filteredMedia =
    data.attributes.media.data?.filter((media) => {
      return media.attributes.mime.includes('image');
    }) || [];

  return (
    <Link
      to={`/catalog/${data.id}`}
      className="catalog-item"
      state={{ search: location.search }}
    >
      <div className="catalog-item-preview">
        <CatalogItemPreview
          media={filteredMedia}
          size="small"
          enableFullscreen={false}
        />
      </div>
      <img
        className="catalog-item-icon"
        src={getAssetUrl(
          data.attributes.categories.data[0]?.attributes.icon.data.attributes
            .url
        )}
      />
      <p className="catalog-item-name">{data.attributes.name}</p>
      <p className="catalog-item-model">{data.attributes.model}</p>

      <CatalogItemDimensions dimensions={data.attributes.dimensions} />
    </Link>
  );
};

CatalogItem.propTypes = {
  data: PropTypes.object,
};

export default CatalogItem;
