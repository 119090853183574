import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getAssetUrl, getAssetUrlBySize, onImageLoaded } from 'helpers';
import LazyLoad from 'react-lazy-load';

import CatalogItemPreviewButton from 'Components/CatalogItemPreviewButton';

import closeIcon from 'assets/img/icon--close.svg';

const CatalogItemPreview = ({ media, size = '', enableFullscreen = false }) => {
  const [fullscreenState, setFullscreenState] = useState(false);

  const getMediaElement = (data) => {
    const isVideo = data.attributes.mime.includes('video');

    if (isVideo) {
      return (
        <video playsInline src={getAssetUrl(data.attributes.url)} controls />
      );
    }

    return (
      <LazyLoad>
        <img src={getAssetUrlBySize(data, size)} onLoad={onImageLoaded} />
      </LazyLoad>
    );
  };

  const onSlideChange = (data) => {
    data.$('video').forEach((video) => video.pause());
  };

  const onSlideClick = () => {
    if (!enableFullscreen) return;
    setFullscreenState(true);
  };

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (key !== 'Escape') return;
      setFullscreenState(false);
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onkeydown);
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={50}
      grabCursor={true}
      onSlideChange={onSlideChange}
      loop={media.length > 1}
      onInit={(swiper) => swiper.slideToLoop(0)}
      className={fullscreenState ? 'swiper--fullscreen' : ''}
    >
      {media.map((data, index) => (
        <SwiperSlide
          key={`${index}-${data.attributes.url}`}
          onClick={onSlideClick}
        >
          {getMediaElement(data)}
        </SwiperSlide>
      ))}
      {media.length > 1 && (
        <>
          <CatalogItemPreviewButton action="slidePrev" />
          <CatalogItemPreviewButton action="slideNext" />
        </>
      )}
      {fullscreenState && (
        <button
          className="swiper-close"
          onClick={() => setFullscreenState(false)}
        >
          <img src={closeIcon} alt="close-icon" />
        </button>
      )}
    </Swiper>
  );
};

CatalogItemPreview.propTypes = {
  enableFullscreen: PropTypes.bool,
  media: PropTypes.array,
  size: PropTypes.string,
};

export default CatalogItemPreview;
