export const deviceMedia = {
  mobile: 'only screen and (max-width: 600px) and (orientation: portrait)',
  tablet: 'only screen and (max-width: 1200px) and (orientation: portrait)',
  mobileLandscape:
    'only screen and (max-width: 900px) and (orientation: landscape)',
  tabletLandscape:
    'only screen and (max-width: 1440px) and (orientation: landscape)',
};

export const deviceSizes = {
  mobile: 600,
  tablet: 1200,
};
