import PropTypes from 'prop-types';

const CatalogItemDimensions = ({ dimensions }) => {
  return (
    <ul className="dimensions">
      <li style={{ '--label': '"L"' }}>{dimensions?.length}</li>
      <li style={{ '--label': '"W"' }}>{dimensions?.width}</li>
      <li style={{ '--label': '"H"' }}>{dimensions?.height}</li>
    </ul>
  );
};

CatalogItemDimensions.propTypes = {
  dimensions: PropTypes.object,
};

export default CatalogItemDimensions;
