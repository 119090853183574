import { makeAutoObservable } from 'mobx';

class LoadingStore {
  loading = false;

  loadingData = { loaded: 0, total: 1, title: 'Загрузка...' };

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingState(loading) {
    this.loading = loading;
  }

  setLoadingData(loadingData) {
    this.loadingData = { ...loadingData };
  }
}

export default new LoadingStore();
