import qs from 'qs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import apiStore from 'store/apiStore';
import loadingStore from 'store/loadingStore';
import constructorStore from 'store/constructorStore';
import { materials } from 'enums/materials';
import { getAssetUrl } from 'helpers';
import { Color } from 'three';

import Logo from 'Components/Logo';
import CloseGroup from 'Components/CloseGroup';
import Constructor from 'Components/Constructor';
import CatalogItemPreview from 'Components/CatalogItemPreview';
import CatalogItemDimensions from 'Components/CatalogItemDimensions';
import RecommendationsList from 'Components/RecommendationsList';
import LoaderSpin from 'Components/LoaderSpin';
import Animation from 'Components/Animation';
import Copyright from 'Components/Copyright';
import PDFModal from 'Components/PDFModal';

import playIcon from 'assets/img/icon--play-1.svg';
import checkboxIcon from 'assets/img/icon--checkbox.svg';
import downloadIcon from 'assets/img/icon--download.svg';
import constructorIcon from 'assets/img/icon--3d.svg';
import shadowImage from 'assets/img/shadow.png';

const Model = observer(() => {
  const searchParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const [modalState, setModalState] = useState(false);
  const [pdfModalState, setPdfModalState] = useState(false);
  const [constructorState, setConstructorState] = useState(false);
  const [modelData, setModelData] = useState({});
  const { id } = useParams();

  const setSearchParams = (params) => {
    const url = new URL(location.origin);
    url.pathname = location.pathname;
    url.search = qs.stringify(params);

    window.history.pushState(null, '', url);
  };

  const openConstructor = () => {
    setConstructorState(true);

    loadingStore.setLoadingState(true);

    return constructorStore
      .loadHDR()
      .then(() => constructorStore.environment.loadModel())
      .then(() => {
        const paths = constructorStore.environment.getTexturePathsByIndices({
          wallIndex: 1,
          floorIndex: 0,
          curtainIndex: 0,
        });

        return constructorStore.environment.loadTextures(paths);
      })
      .then(() => {
        const shadowUrl =
          modelData.attributes.shadow.data?.attributes.url || null;

        return constructorStore.createGrid(
          shadowUrl ? getAssetUrl(shadowUrl) : shadowImage
        );
      })
      .then(() =>
        constructorStore.loadModel(
          getAssetUrl(modelData.attributes.glb_model.data.attributes.url)
        )
      )
      .then(async () => {
        const promises = materials.map(({ name }) => {
          return new Promise((resolve) => {
            constructorStore.applyMaterial(name, 'Material-1').then(resolve);
          });
        });

        return Promise.all(promises);
      })
      .then(() => constructorStore.applyMaterial('Рогожка', 'Material-1'))
      .then(() => constructorStore.applyMaterial('Пластик', 'Material-2'))
      .then(() => constructorStore.applyMaterial('Рогожка', 'Material-3'))
      .then(() => constructorStore.applyMaterial('Дерево', 'Material-4'))
      .then(() => constructorStore.applyMaterial('Рогожка', 'Material-5'))
      .then(() => constructorStore.applyMaterial('Дерево', 'Material-6'))
      .then(() => constructorStore.applyMaterial('Металл', 'Material-7'))
      .then(() => constructorStore.applyMaterial('Металл', 'Material-8'))
      .then(() => constructorStore.applyMaterial('Дерево', 'Material-9'))
      .then(() => constructorStore.applyMaterial('Рогожка', 'Material-10'))
      .then(() => constructorStore.applyMaterial('Ткань', 'Material-11'))
      .then(() => {
        const { data } = modelData.attributes.decor_materials;
        if (data && data[0]) {
          const color = new Color(data[0].attributes.color);
          constructorStore.setMaterialColor(color, 'Material-4');
        }
      })
      .finally(() => {
        constructorStore.setCurrentLayer('Material-1');
        constructorStore.render();
        loadingStore.setLoadingState(false);

        setSearchParams({ open: 1 });

        if (!constructorStore.controls.instructionTimeout) {
          constructorStore.controls.startInstruction(2000);
        }
      });
  };

  const closeConstructor = () => {
    setConstructorState(false);

    setSearchParams({});
  };

  const openModal = () => {
    setModalState(true);
  };

  const closeModal = () => {
    setModalState(false);
  };

  const goBack = () => {
    const { state } = window.history;
    navigate(`/catalog${state?.usr?.search || ''}`);
  };

  const openPDFViewer = () => {
    setPdfModalState(true);
  };

  const closePDFViewer = () => {
    setPdfModalState(false);
  };

  useEffect(() => {
    apiStore.setLoadingState(true);
    apiStore
      .loadFurnitureById(id)
      .then((data) => {
        if (!data) goBack();
        setModelData(data || {});
      })
      .finally(() => apiStore.setLoadingState(false));
  }, [id]);

  useEffect(() => {
    if (!modelData.attributes) return;
    if (searchParams.get('open') !== '1') return;

    openConstructor();
  }, [modelData]);

  return (
    <section className="model-page">
      <Logo white={false} />

      <CloseGroup onBack={goBack} />

      <div className="content">
        <div className="model-info" key={`model-${modelData.id}`}>
          <div className="catalog-item-preview">
            {modelData.attributes && (
              <CatalogItemPreview
                media={modelData.attributes.media.data}
                enableFullscreen={true}
              />
            )}
          </div>
          <div className="model-info-text">
            <div className="model-info-header">
              <div className="model-info-title">
                <h4>{modelData.attributes?.name}</h4>
                <p>{modelData.attributes?.model}</p>
              </div>
              <div className="model-info-buttons">
                {modelData.attributes?.instruction.data && (
                  <button className="model-info-button" onClick={openPDFViewer}>
                    <img src={downloadIcon} alt="download-icon" />
                  </button>
                )}
                {modelData.attributes?.glb_model.data && (
                  <button
                    className="model-info-button"
                    onClick={openConstructor}
                  >
                    <img src={constructorIcon} alt="3d-icon" />
                  </button>
                )}
                {modelData.attributes?.animation.data && (
                  <button className="model-info-button" onClick={openModal}>
                    <img src={playIcon} alt="play-icon" />
                  </button>
                )}
              </div>
            </div>

            <div className="model-info-body">
              <div className="model-info-drawing">
                {modelData.attributes?.dimensions.drawing.data && (
                  <>
                    <img
                      src={getAssetUrl(
                        modelData.attributes?.dimensions.drawing.data.attributes
                          .url
                      )}
                      alt="drawing"
                    />
                    <CatalogItemDimensions
                      dimensions={modelData.attributes?.dimensions}
                    />
                  </>
                )}
              </div>
              <div className="model-info-params">
                <p>{modelData.attributes?.description}</p>
                <CatalogItemDimensions
                  dimensions={modelData.attributes?.dimensions}
                />
              </div>
            </div>

            <ul className="model-info-advantages">
              {(modelData.attributes?.advantages || []).map((adv, index) => (
                <li key={index}>
                  <img src={checkboxIcon} alt="checkbox-icon" />
                  <span>{adv.name}</span>
                </li>
              ))}
            </ul>
          </div>

          {apiStore.loading && <LoaderSpin />}
        </div>

        <RecommendationsList />

        <Copyright />
      </div>

      {modalState && (
        <Animation
          name={modelData.attributes.mechanism.data?.attributes.name}
          url={modelData.attributes.animation.data.attributes.url}
          onClose={closeModal}
        />
      )}

      {constructorState && (
        <Constructor
          name={`${modelData.attributes?.name} ${modelData.attributes?.model}`}
          decorMaterials={modelData.attributes?.decor_materials.data || []}
          animationUrl={modelData.attributes?.animation.data?.attributes.url}
          openAnimation={openModal}
          onClose={closeConstructor}
        />
      )}

      {pdfModalState && (
        <PDFModal
          file={modelData.attributes?.instruction.data}
          onClose={closePDFViewer}
        />
      )}
    </section>
  );
});

export default Model;
