import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import apiStore from 'store/apiStore';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import Logo from 'Components/Logo';
import Filters from 'Components/Filters';
import LoaderSpin from 'Components/LoaderSpin';
import CatalogItem from 'Components/CatalogItem';
import CatalogCategories from 'Components/CatalogCategories';
import Searchbar from 'Components/Searchbar';

import chevronRightIcon from 'assets/img/icon--chevron-right.svg';
import magnifyIcon from 'assets/img/icon--magnify.svg';
import houseIcon from 'assets/img/icon--house-1.svg';
import filterIcon from 'assets/img/icon--filter.svg';
import closeIcon from 'assets/img/icon--close.svg';
import infoIcon from 'assets/img/icon--info.svg';

const Catalog = observer(() => {
  return (
    <section className="catalog-page">
      <Logo white={false} />

      <Link to="/" className="catalog-mobile-close">
        <img src={houseIcon} />
      </Link>

      <MobileSearch />

      <div className="content">
        <FiltersWrapper />

        <div className="catalog-mobile-overflow">
          <CatalogCategories />

          <div className="catalog-list">
            {apiStore.furniture.map((furniture, index) => (
              <CatalogItem key={`${furniture.id}-${index}`} data={furniture} />
            ))}

            {apiStore.filtersLoaded &&
              !apiStore.loading &&
              !apiStore.furniture.length && (
                <div className="catalog-list-empty">
                  <div className="catalog-list-empty-icon">
                    <img src={infoIcon} alt="info-icon" />
                  </div>
                  <p>Совпадений не найдено. Измените параметры поиска</p>
                </div>
              )}
          </div>

          {apiStore.loading && <LoaderSpin />}

          {apiStore.totalPagesCount > 0 && <Pagination />}
        </div>
      </div>
    </section>
  );
});

const Pagination = observer(() => {
  const [swiper, setSwiper] = useState(null);

  const changePage = (page) => {
    if (apiStore.currentPage === page) return;

    apiStore.setCurrentPage(page);
    apiStore.loadFilteredFurnitures(page);
    apiStore.updateSearchParams();
  };

  const getButtonClassName = (page) => {
    let className = 'catalog-pagination-button';

    if (apiStore.currentPage === page) {
      return `${className} catalog-pagination-button--active`;
    }

    return className;
  };

  const onInit = (data) => {
    data.slideTo(apiStore.currentPage - 1);
    setSwiper(data);
  };

  useEffect(() => {
    if (!swiper) return;

    const newIndex = apiStore.currentPage - 1;

    if (swiper.activeIndex - newIndex > 0) {
      swiper.slideTo(apiStore.currentPage - 1);
    }
  }, [apiStore.currentPage]);

  const slidesCount = Math.min(4, apiStore.totalPagesCount);
  const pagesKeys = Array.from(Array(apiStore.totalPagesCount).keys());

  return (
    <div className="catalog-pagination">
      <button className="catalog-pagination-arrow catalog-pagination-arrow--prev">
        <img src={chevronRightIcon} alt="chevron-left" />
      </button>

      <Swiper
        style={{ '--slides-count': slidesCount }}
        threshold={5}
        slidesPerView={slidesCount}
        spaceBetween={0}
        grabCursor={true}
        modules={[Navigation]}
        onInit={onInit}
        navigation={{
          enabled: true,
          nextEl: '.catalog-pagination-arrow--next',
          prevEl: '.catalog-pagination-arrow--prev',
        }}
      >
        {pagesKeys.map((page) => (
          <SwiperSlide key={page}>
            <button
              className={getButtonClassName(page + 1)}
              onClick={() => changePage(page + 1)}
            >
              {page + 1}
            </button>
          </SwiperSlide>
        ))}
      </Swiper>

      <button className="catalog-pagination-arrow catalog-pagination-arrow--next">
        <img src={chevronRightIcon} alt="chevron-right" />
      </button>
    </div>
  );
});

const FiltersWrapper = observer(() => {
  const [active, setActiveState] = useState(false);

  const onButtonClick = (e, value) => {
    e.stopPropagation();
    setActiveState(value);
  };

  return (
    <div
      className={`filters-wrapper ${active ? 'filters-wrapper--active' : ''}`}
      onClick={() => setActiveState(false)}
    >
      {active ? (
        <button
          className="filters-wrapper-button filters-wrapper-button--active"
          onClick={(e) => onButtonClick(e, false)}
        >
          <img src={closeIcon} />
        </button>
      ) : (
        <button
          className="filters-wrapper-button"
          onClick={(e) => onButtonClick(e, true)}
        >
          <img src={filterIcon} />
        </button>
      )}

      <Filters onClose={() => setActiveState(false)} />
    </div>
  );
});

const MobileSearch = observer(() => {
  const [active, setActiveState] = useState(false);

  const close = () => setActiveState(false);

  const onButtonClick = (e, value) => {
    e.stopPropagation();
    setActiveState(value);
  };

  const onSubmitSearch = () => {
    apiStore.setCurrentPage(1);
    apiStore.loadFilteredFurnitures();
    apiStore.updateSearchParams();
    close();
  };

  return (
    <div
      className={`mobile-search ${active ? 'mobile-search--active' : ''}`}
      onClick={close}
    >
      <button
        className={`mobile-search-toggle ${
          active ? 'mobile-search-toggle--active' : ''
        }`}
        onClick={(e) => onButtonClick(e, true)}
      >
        <img src={magnifyIcon} />
      </button>

      <div className="mobile-search-wrapper">
        <div
          className="mobile-search-content"
          onClick={(e) => e.stopPropagation()}
        >
          <button className="mobile-search-close" onClick={close}>
            <img src={closeIcon} />
          </button>

          <Searchbar onSubmit={onSubmitSearch} />

          <button className="mobile-search-submit" onClick={onSubmitSearch}>
            Показать
          </button>
        </div>
      </div>
    </div>
  );
});

export default Catalog;
