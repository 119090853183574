import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper';
import apiStore from 'store/apiStore';
import { deviceMedia } from 'enums/device';

import RecommendationsItem from 'Components/RecommendationsItem';

import hitsPad from 'assets/img/hits-pad.svg';
import hitsPadMobile from 'assets/img/hits-pad--mobile.svg';
import likeIcon from 'assets/img/icon--like.svg';
import starIcon from 'assets/img/icon--star.svg';
import arrowIcon from 'assets/img/icon--arrow-right.svg';

const RecommendationsList = observer(
  ({ pad = hitsPad, mobilePad = hitsPadMobile }) => {
    const [currentTab, setCurrentTab] = useState('hits');
    const [swiper, setSwiper] = useState(null);

    const tabs = [
      { title: 'Хиты продаж', icon: likeIcon, value: 'hits' },
      { title: 'Новинки', icon: starIcon, value: 'new' },
    ];

    return (
      <div className="recommendations">
        <picture>
          <source srcSet={mobilePad} media={deviceMedia.mobile} />
          <img src={pad} className="recommendations-pad" alt="pad" />
        </picture>

        <div className="recommendations-tabs">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`recommendations-tab ${
                tab.value === currentTab ? 'recommendations-tab--active' : ''
              }`}
              onClick={() => setCurrentTab(tab.value)}
            >
              <img src={tab.icon} />
              <span>{tab.title}</span>
            </button>
          ))}
        </div>

        <div className="recommendations-list">
          <Swiper
            modules={[Mousewheel]}
            slidesPerView={'auto'}
            grabCursor={true}
            threshold={5}
            onSwiper={(data) => setSwiper(data)}
            mousewheel={true}
          >
            {apiStore.recommendations[currentTab].map((data, index) => (
              <SwiperSlide key={`${index}-${data.id}`}>
                <RecommendationsItem data={data} />
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            className="swiper-button-next"
            onClick={() => swiper.slideNext()}
          >
            <img src={arrowIcon} />
          </button>
        </div>
      </div>
    );
  }
);

RecommendationsList.propTypes = {
  mobilePad: PropTypes.string,
  pad: PropTypes.string,
};

export default RecommendationsList;
