import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import arrowIcon from 'assets/img/icon--chevron-left.svg';
import houseIcon from 'assets/img/icon--house-1.svg';

const CloseGroup = ({ onBack }) => {
  return (
    <div className="close-group">
      <button
        className="close-group-button close-group-button--back"
        onClick={onBack}
      >
        <img src={arrowIcon} alt="arrow-icon" />
      </button>
      <Link to="/">
        <button className="close-group-button close-group-button--house">
          <img src={houseIcon} alt="house-icon" />
        </button>
      </Link>
    </div>
  );
};

CloseGroup.propTypes = {
  onBack: PropTypes.func,
};

export default CloseGroup;
