import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { curtains, floors, walls } from 'enums/presets';
import constructorStore from 'store/constructorStore';
import loadingStore from 'store/loadingStore';
import * as THREE from 'three';

import wallsPad from 'assets/img/walls-pad.svg';
import curtainsPad from 'assets/img/curtains-pad.svg';

const PresetPicker = observer(() => {
  const [indices, setIndices] = useState({
    wallIndex: 1,
    floorIndex: 0,
    curtainIndex: 0,
  });

  const changeTexture = (index, key) => {
    const newIndices = { ...indices, [key]: index };
    setIndices(newIndices);
  };

  const loadTextures = () => {
    const { diffuse, normal, roughness } =
      constructorStore.environment.getTexturePathsByIndices(indices);

    const allCached = [diffuse, normal, roughness].every((url) => {
      return THREE.Cache.get(url);
    });

    if (!allCached) {
      loadingStore.setLoadingState(true);
    }

    constructorStore.environment
      .loadTextures({ diffuse, normal, roughness })
      .then(() => {
        constructorStore.render();
        if (!allCached) {
          loadingStore.setLoadingState(false);
        }
      });
  };

  useEffect(() => {
    if (!constructorStore.environmentVisible) return;
    loadTextures(indices);
  }, [indices]);

  return (
    <div className="constructor-preset-picker">
      <div className="constructor-wall-picker">
        <p>Стены</p>
        <img className="constructor-preset-picker-pad" src={wallsPad} />
        {walls.map((wall, index) => (
          <button
            key={index}
            onClick={() => changeTexture(index, 'wallIndex')}
            className={index === indices.wallIndex ? 'active' : ''}
          >
            <img src={wall.img} />
            <span>{wall.name}</span>
          </button>
        ))}
      </div>

      <div className="constructor-floor-picker">
        <p>Пол</p>
        <img className="constructor-preset-picker-pad" src={wallsPad} />
        {floors.map((floor, index) => (
          <button
            key={index}
            onClick={() => changeTexture(index, 'floorIndex')}
            className={index === indices.floorIndex ? 'active' : ''}
          >
            <img src={floor.img} />
            <span>{floor.name}</span>
          </button>
        ))}
      </div>

      <div className="constructor-curtain-picker">
        <p>Портьеры</p>
        <img className="constructor-preset-picker-pad" src={curtainsPad} />
        {curtains.map((curtain, index) => (
          <button
            key={index}
            onClick={() => changeTexture(index, 'curtainIndex')}
            style={{ background: curtain.color }}
            className={index === indices.curtainIndex ? 'active' : ''}
          />
        ))}
      </div>
    </div>
  );
});

export default PresetPicker;
