import { observer } from 'mobx-react';
import { useEffect } from 'react';
import apiStore from 'store/apiStore';

import BranchesList from 'Pages/Contacts/BranchesList';

const Warehouses = observer(() => {
  useEffect(() => {
    if (apiStore.warehousesData.length > 0) return;
    apiStore.loadWarehouses();
  }, []);

  return (
    <div className="warehouses-page">
      <BranchesList list={[...apiStore.warehousesData]} />
    </div>
  );
});

export default Warehouses;
