import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import loadingStore from 'store/loadingStore';

const Loader = observer(({ transparent = false }) => {
  if (!loadingStore.loading) return null;

  return (
    <div className={`loader ${transparent ? 'loader--transparent' : ''}`}>
      <p>{loadingStore.loadingData.title}</p>
      <span className="loader-circle" />
    </div>
  );
});

Loader.propTypes = {
  transparent: PropTypes.bool,
};

export default Loader;
