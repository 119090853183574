import { observer } from 'mobx-react';

import Advertisement from 'Pages/Documents/Advertisement';
import Declarations from 'Pages/Documents/Declarations';
import InfoPagesWrapper from 'Pages/InfoPagesWrapper';
import InfoPagesTabs from 'Pages/InfoPagesTabs';
import Fabrics from 'Pages/Documents/Fabrics';

const Documents = observer(() => {
  const tabs = [
    {
      name: 'Ткани',
      content: <Fabrics />,
    },
    {
      name: 'Рекламные материалы',
      content: <Advertisement />,
    },
    {
      name: 'Декларации',
      content: <Declarations />,
    },
  ];

  return (
    <InfoPagesWrapper>
      <InfoPagesTabs tabs={tabs} />
    </InfoPagesWrapper>
  );
});

export default Documents;
