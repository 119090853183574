import { makeAutoObservable } from 'mobx';
import { getMaterialByName } from 'enums/materials';
import loadingStore from 'store/loadingStore';
import TextureLoader from './textureLoader';
import * as THREE from 'three';

class MaterialLoader {
  constructor() {
    makeAutoObservable(this);
  }

  load(name) {
    const data = getMaterialByName(name);

    const getTexturePromises = (data) => {
      return new Promise((resolve) => {
        this.loadTexture(data).then((texture) => {
          resolve({ key: data.key, texture });
        });
      });
    };

    return new Promise((resolve) => {
      const material = new data.material.constructor({
        ...data.material,
        envMapIntensity: 0.5,
      });

      const texturePromises = data.material.textures.map(getTexturePromises);

      Promise.all(texturePromises).then((textures) => {
        textures.forEach(({ key, texture }) => {
          material[key] = texture;
        });

        THREE.Cache.add(`material-${name}`, material);
        material.materialName = name;

        resolve(material);
      });
    });
  }

  async loadTexture({ key, url, repeat = [25, 25] }) {
    const loader = new TextureLoader();

    const onProgress = ({ total, loaded }) => {
      loadingStore.setLoadingData({
        title: 'Загружаю текстуры...',
        loaded,
        total,
      });
    };

    const texture = await loader.loadAsync(url, onProgress);

    if (key === 'map') {
      texture.encoding = THREE.sRGBEncoding;
    }

    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(...repeat);

    return texture;
  }
}

export default MaterialLoader;
