import * as THREE from 'three';

export default {
  name: 'Металл',
  material: {
    constructor: THREE.MeshStandardMaterial,
    roughness: 0.2,
    metalness: 1,
    textures: [],
  },
};
