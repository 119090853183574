import { observer } from 'mobx-react';

import InfoPagesWrapper from 'Pages/InfoPagesWrapper';
import Warehouses from 'Pages/Contacts/Warehouses';
import InfoPagesTabs from 'Pages/InfoPagesTabs';
import Factory from 'Pages/Contacts/Factory';
import Salons from 'Pages/Contacts/Salons';

const Contacts = observer(() => {
  const tabs = [
    {
      name: 'Фабрика',
      content: <Factory />,
    },
    {
      name: 'Фирменные салоны',
      content: <Salons />,
    },
    {
      name: 'Оптовые склады',
      content: <Warehouses />,
    },
  ];

  return (
    <InfoPagesWrapper>
      <InfoPagesTabs tabs={tabs} />
    </InfoPagesWrapper>
  );
});

export default Contacts;
