const Copyright = () => {
  return (
    <p className="copyright">
      <span>
        Copyright 2006-2016. Фабрика мягкой мебели &quot;Апогей&quot;. Все права
        защищены.
      </span>
      <span>www.apogey-mebel.ru</span>
    </p>
  );
};

export default Copyright;
