import { createBrowserRouter, Navigate } from 'react-router-dom';

import Documents from 'Pages/Documents/Index';
import Contacts from 'Pages/Contacts/Index';
import Catalog from 'Pages/Catalog';
import Model from 'Pages/Model';
import Login from 'Pages/Login';
import About from 'Pages/About';
import Home from 'Pages/Home';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <Navigate to="/" />,
  },
  {
    path: '/catalog',
    element: <Catalog />,
  },
  {
    path: '/catalog/:id',
    element: <Model />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/contacts',
    element: <Contacts />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/documents',
    element: <Documents />,
  },
]);
