import { useEffect } from 'react';
import { observer } from 'mobx-react';
import apiStore from 'store/apiStore';

import InfoPagesWrapper from 'Pages/InfoPagesWrapper';

const About = observer(() => {
  useEffect(() => {
    if (apiStore.contactsData) return;
    apiStore.loadContacts();
  }, []);

  return (
    <InfoPagesWrapper>
      <div className="about-page">
        <h1>{apiStore.contactsData?.attributes.factory_name}</h1>
        <div className="about-page-row">
          <div className="about-page-video">
            {apiStore.contactsData && (
              <iframe
                src={`https://www.youtube.com/embed/${apiStore.contactsData.attributes.video}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            )}
          </div>
          <div className="about-page-text">
            {(apiStore.contactsData?.attributes.paragraphs || []).map(
              (paragraph, index) => (
                <p key={index} style={{ order: index + 1 }}>
                  {paragraph.text}
                </p>
              )
            )}
            <div className="about-page-text-numbers">
              {(apiStore.contactsData?.attributes.numbers || []).map(
                (number, index) => (
                  <div className="about-page-text-number" key={index}>
                    <b>{number.number}</b>
                    <span>{number.addon}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </InfoPagesWrapper>
  );
});

export default About;
