import { configure, makeAutoObservable } from 'mobx';
import UAParser from 'ua-parser-js';

configure({ useProxies: 'never' });

class DeviceStore {
  parser = null;

  constructor() {
    makeAutoObservable(this);

    this.parser = new UAParser();
  }

  get device() {
    return this.parser.getDevice();
  }

  get isMobile() {
    return this.device.type === 'mobile';
  }

  get isTablet() {
    return this.device.type === 'tablet';
  }
}

export default new DeviceStore();
