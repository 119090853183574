import qs from 'qs';
import { useState } from 'react';
import { observer } from 'mobx-react';

const InfoPagesTabs = observer(({ tabs }) => {
  const searchParams = new URLSearchParams(location.search);
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(searchParams.get('tab'))
  );

  const onTabChange = (tabIndex) => {
    const url = new URL(location.origin);
    url.pathname = location.pathname;
    url.search = qs.stringify({ tab: tabIndex });

    window.history.pushState(null, '', url);

    setActiveTabIndex(tabIndex);
  };

  return (
    <div className="info-page-tabs">
      <div className="info-page-tabs-content">
        <div className="info-page-tabs-buttons">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => onTabChange(index)}
              className={index === activeTabIndex ? 'active' : ''}
            >
              {tab.name}
            </button>
          ))}
        </div>

        {tabs[activeTabIndex].content}
      </div>
    </div>
  );
});

export default InfoPagesTabs;
