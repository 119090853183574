import * as THREE from 'three';
import { STATIC_MEDIA_ENDPOINT } from 'config';
import loadingStore from 'store/loadingStore';
import TextureLoader from './textureLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import envModel from 'assets/models/Environment.glb';

class ConstructorEnvironment extends THREE.Group {
  model;

  scene;

  constructor() {
    super();

    this.hide();
  }

  show(model) {
    this.model = model;
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  onProgress({ total, loaded }) {
    loadingStore.setLoadingData({
      title: 'Загружаю окружение...',
      loaded,
      total,
    });
  }

  getTexturePathsByIndices({ wallIndex, floorIndex, curtainIndex }) {
    const diffuse = `${STATIC_MEDIA_ENDPOINT}/diffuse-${wallIndex}-${floorIndex}-${curtainIndex}.png`;
    const roughness = `${STATIC_MEDIA_ENDPOINT}/roughness-${wallIndex}-${floorIndex}.png`;
    const normal = `${STATIC_MEDIA_ENDPOINT}/normal-${wallIndex}-${floorIndex}.png`;

    return { roughness, diffuse, normal };
  }

  async loadModel() {
    if (this.scene) return;

    const onLoad = ({ scene }) => {
      scene.position.y = -0.01;

      this.scene = scene;
      this.add(this.scene);
    };

    return await new GLTFLoader()
      .loadAsync(envModel, this.onProgress.bind(this))
      .then(onLoad);
  }

  async loadTextures({ diffuse, normal, roughness }) {
    const roughnessMap = await this.loadTexture(roughness);
    const normalMap = await this.loadTexture(normal);
    const map = await this.loadTexture(diffuse);

    map.encoding = THREE.sRGBEncoding;

    this.scene.children[0].material = new THREE.MeshPhysicalMaterial({
      envMapIntensity: 0.83,
      specularIntensity: 0,
      roughness: 1,
      metalness: 0,
      roughnessMap,
      normalMap,
      map,
    });
  }

  async loadTexture(url) {
    const loader = new TextureLoader();

    const texture = await loader.loadAsync(url, this.onProgress.bind(this));

    texture.flipY = false;

    return texture;
  }
}

export default ConstructorEnvironment;
