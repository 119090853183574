import { useEffect } from 'react';
import { observer } from 'mobx-react';
import apiStore from 'store/apiStore';

import FilterOption from 'Components/FilterOption';
import FilterSelect from 'Components/FilterSelect';

const Filters = observer(({ onClose }) => {
  const searchParams = new URLSearchParams(location.search);

  const filterFields = [
    { key: 'sitting_places', api: 'sitting-places' },
    { key: 'mechanism', api: 'mechanisms' },
    { key: 'comfort', api: 'comforts' },
  ];

  const loadFiltersData = async () => {
    const promises = filterFields.map(({ key, api }) => {
      return apiStore.get(api, '').then(({ data }) => ({ key, data }));
    });

    const response = await Promise.all(promises);
    response.forEach(({ key, data }) => {
      apiStore.setFiltersData(key, data);
    });

    return apiStore.setFiltersLoadedState(true);
  };

  const getFilterOption = (option, index, groupKey) => (
    <FilterOption
      key={index}
      title={option.attributes.name}
      subtitle={option.attributes.description}
      checked={option.attributes.checked}
      onChange={(checked) => onChange(groupKey, index, checked)}
    />
  );

  const onChange = (groupKey, index, checked, load = true) => {
    apiStore.setOptionData(groupKey, index, checked);
    apiStore.updateSearchParams();

    if (!load) return;

    apiStore.loadFilteredFurnitures();
  };

  const onBooleanChange = (key, checked) => {
    apiStore.setBooleanOptionData(key, checked);
    apiStore.updateSearchParams();
    apiStore.loadFilteredFurnitures();
  };

  const onSelectChange = (groupKey, id) => {
    const index = apiStore.filters[groupKey].findIndex(
      (option) => option.id === id
    );

    apiStore.filters[groupKey].forEach((_, i) => {
      onChange(groupKey, i, false, false);
    });

    if (index === -1) {
      return apiStore.loadFilteredFurnitures();
    }

    onChange(groupKey, index, true, true);
  };

  const setFiltersFromQuery = () => {
    Object.entries(apiStore.filters).forEach(([key, value]) => {
      if (typeof value === 'boolean') {
        apiStore.setBooleanOptionData(key, searchParams.get(key) === '1');
        return;
      }

      if (typeof value === 'string') {
        apiStore.setStringOptionData(key, searchParams.get(key) || '');
        return;
      }

      value.forEach((option, index) => {
        const paramsIds = (searchParams.get(key) || '')
          .split(',')
          .map((id) => Number(id));

        apiStore.setOptionData(key, index, paramsIds.includes(option.id));
      });
    });
  };

  const onSubmit = () => {
    apiStore.loadFilteredFurnitures();
    onClose();
  };

  const resetFilters = () => {
    Object.entries(apiStore.filters).forEach(([key, value]) => {
      if (typeof value === 'boolean') {
        return apiStore.setBooleanOptionData(key, false);
      }

      if (typeof value === 'string') {
        return apiStore.setStringOptionData(key, '');
      }

      value.forEach((_, index) => {
        apiStore.setOptionData(key, index, false);
      });
    });

    apiStore.setCurrentPage(1);
    apiStore.loadFilteredFurnitures();
    apiStore.updateSearchParams();
  };

  useEffect(() => {
    apiStore.setCurrentPage(Number(searchParams.get('page') || 1));

    const ids = (searchParams.get('categories') || '')
      .split(',')
      .map((id) => Number(id))
      .filter((id) => id > 0);

    if (ids.length) {
      apiStore.setChosenCategories(ids);
    } else {
      apiStore.setChosenCategories(apiStore.categories.map(({ id }) => id));
    }

    if (apiStore.filtersLoaded) {
      setFiltersFromQuery();
      apiStore.loadFilteredFurnitures().then(() => {
        apiStore.updateSearchParams();
      });
      return;
    }

    loadFiltersData().then(() => {
      setFiltersFromQuery();
      apiStore.loadFilteredFurnitures().then(() => {
        apiStore.updateSearchParams();
      });
    });
  }, []);

  return (
    <div className="filters" onClick={(e) => e.stopPropagation()}>
      <div className="filters-header">
        <p>Фильтры</p>
        <button onClick={resetFilters}>Сбросить</button>
      </div>
      {apiStore.filters.sitting_places.length > 0 && (
        <FilterSelect
          placeholder="Посадочные места"
          value={
            apiStore.filters.sitting_places.find(
              (option) => option.attributes.checked
            )?.id || -1
          }
          options={apiStore.filters.sitting_places.map((option) => ({
            title: option.attributes.name,
            value: option.id,
          }))}
          onChange={(value) => onSelectChange('sitting_places', value)}
        />
      )}

      <hr />

      <h4>Комфорт</h4>
      <div className="filters-options-group filters-options-group--full">
        {apiStore.filters.comfort.map((option, index) =>
          getFilterOption(option, index, 'comfort')
        )}
      </div>

      <hr />

      <h4>Механизм раскладки</h4>
      <div className="filters-options-group">
        {apiStore.filters.mechanism.map((option, index) =>
          getFilterOption(option, index, 'mechanism')
        )}
      </div>

      <hr />

      <div className="filters-options-group filters-options-group--full">
        <FilterOption
          title="Высокие опоры"
          checked={apiStore.filters.high_legs}
          onChange={(checked) => onBooleanChange('high_legs', checked)}
        />
        <FilterOption
          title="Металлокаркас"
          checked={apiStore.filters.metal_frame}
          onChange={(checked) => onBooleanChange('metal_frame', checked)}
        />
        <FilterOption
          title="Наличие декора"
          checked={apiStore.filters.decor}
          onChange={(checked) => onBooleanChange('decor', checked)}
        />
        <FilterOption
          title="Отделение для хранения"
          checked={apiStore.filters.storage}
          onChange={(checked) => onBooleanChange('storage', checked)}
        />
        <FilterOption
          title="ЗD модель"
          checked={apiStore.filters.glb}
          onChange={(checked) => onBooleanChange('glb', checked)}
        />
      </div>

      <div className="filters-submit-button">
        <button onClick={onSubmit}>Показать</button>
      </div>
    </div>
  );
});

export default Filters;
