import * as THREE from 'three';

export default {
  name: 'Пластик',
  material: {
    constructor: THREE.MeshStandardMaterial,
    roughness: 0.45,
    metalness: 0,
    textures: [],
  },
};
