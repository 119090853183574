import PropTypes from 'prop-types';

import paletteIcon from 'assets/img/icon--palette.svg';
import repairIcon from 'assets/img/icon--repair.svg';

const EnvironmentSwitch = ({ active, onChange }) => {
  const buttons = [
    {
      active: active,
      text: 'Шоурум',
      icon: paletteIcon,
      onClick: () => onChange(true),
    },
    {
      active: !active,
      text: 'Конструктор',
      icon: repairIcon,
      onClick: () => onChange(false),
    },
  ];

  return (
    <div className="constructor-environment-switch">
      {buttons.map((button, i) => (
        <button
          key={i}
          disabled={button.active}
          onClick={button.onClick}
          className="constructor-environment-switch-button"
        >
          <img src={button.icon} alt="button-icon" />
          <span>{button.text}</span>
        </button>
      ))}
    </div>
  );
};

EnvironmentSwitch.propTypes = {
  onChange: PropTypes.func,
  active: PropTypes.bool,
};

export default EnvironmentSwitch;
