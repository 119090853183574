import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Color } from 'three';
import constructorStore, { initialColors } from 'store/constructorStore';
import loadingStore from 'store/loadingStore';
import { selectableMaterials } from 'enums/materials';
import { getHexString, getAssetUrl } from 'helpers';
import { Swiper, SwiperSlide } from 'swiper/react';

import Logo from 'Components/Logo';
import Loader from 'Components/Loader';
import Copyright from 'Components/Copyright';
import CloseGroup from 'Components/CloseGroup';
import PresetPicker from 'Components/PresetPicker';
import EnvironmentSwitch from 'Components/EnvironmentSwitch';
import CatalogItemPreviewButton from 'Components/CatalogItemPreviewButton';

import playIcon from 'assets/img/icon--play.svg';
import rotationIcon from 'assets/img/icon--rotation.svg';

const getContainer = () =>
  document.querySelector('.constructor .constructor-wrapper');

const Constructor = observer((props) => {
  const {
    onClose,
    decorMaterials = [],
    openAnimation,
    animationUrl,
    name,
  } = props;

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const { environmentVisible } = constructorStore;

  const layers = [
    { name: 'Ткань 1', index: 'Material-1' },
    { name: 'Ткань 2', index: 'Material-3' },
    { name: 'Ткань 3', index: 'Material-5' },
  ];

  useEffect(() => {
    const container = getContainer();
    container.appendChild(constructorStore.renderer.domElement);

    const observer = new ResizeObserver(onResize);
    observer.observe(container);

    return () => {
      observer.disconnect();
      setEnvironmentVisibility(false);
      Object.entries(initialColors).forEach(([index, color]) => {
        constructorStore.setMaterialColor(color, index);
      });
      constructorStore.removeModel();
    };
  }, []);

  const onResize = () => {
    const { offsetWidth } = getContainer();
    constructorStore.updateWindowSize(offsetWidth, offsetWidth);
  };

  const setEnvironmentVisibility = (value) => {
    constructorStore.setEnvironmentVisibility(value);
  };

  const onLayerClick = (index) => {
    constructorStore.setCurrentLayer(index);
  };

  const onMaterialClick = async (name) => {
    loadingStore.setLoadingState(true);
    const { currentLayer } = constructorStore;
    const material = await constructorStore.applyMaterial(name, currentLayer);
    constructorStore.setCurrentMaterial(material);
    loadingStore.setLoadingState(false);
    constructorStore.render();
  };

  const onColorChange = ({ target }) => {
    const color = new Color(target.value);
    constructorStore.setMaterialColor(color);
    constructorStore.render();
  };

  const onDecorColorChange = (value) => {
    const color = new Color(value);
    constructorStore.setMaterialColor(color, 'Material-4');
    constructorStore.render();
  };

  const onSlideChange = (data) => {
    setIsBeginning(data.isBeginning);
    setIsEnd(data.isEnd);

    const decorMaterial = decorMaterials[data.realIndex];
    if (!decorMaterial) return;
    onDecorColorChange(decorMaterial.attributes.color);
  };

  return (
    <div
      className={`constructor ${
        environmentVisible ? 'constructor--showroom' : ''
      }`}
    >
      <Loader transparent={environmentVisible} />

      <Logo white={environmentVisible} />

      <CloseGroup onBack={onClose} />

      <div className="constructor-mobile-message">
        <img src={rotationIcon} />
        <p>
          Поверните устройство <br /> горизонтально, будет удобнее
        </p>
      </div>

      <EnvironmentSwitch
        active={environmentVisible}
        onChange={setEnvironmentVisibility}
      />

      <PresetPicker />

      <div className="constructor-name">
        <div className="constructor-name-background" />
        <p>{name}</p>
      </div>

      {animationUrl && (
        <button
          className="constructor-animation-button"
          onClick={openAnimation}
        >
          <img src={playIcon} alt="play-icon" />
          <span>Анимация раскладки</span>
        </button>
      )}

      <div className="constructor-material-picker">
        <div className="constructor-material-picker-layers">
          <p>Текстура и цвет ткани</p>

          {layers.map((layer, index) => (
            <button
              key={index}
              className={
                constructorStore.currentLayer === layer.index ? 'active' : ''
              }
              onClick={() => onLayerClick(layer.index)}
              disabled={!constructorStore.getMeshByName(layer.index)}
            >
              {layer.name}
            </button>
          ))}
        </div>
        <div className="constructor-material-picker-materials">
          {selectableMaterials.map(({ name, preview }, index) => (
            <label
              key={index}
              className={
                constructorStore.currentMaterial.materialName === name
                  ? 'active'
                  : ''
              }
              onClick={() => onMaterialClick(name)}
            >
              <img src={preview} alt="material-preview" />
              <span>{name}</span>
              <input
                type="color"
                value={getHexString(
                  constructorStore.materialColors[constructorStore.currentLayer]
                )}
                onChange={onColorChange}
              />
            </label>
          ))}
        </div>
      </div>

      {decorMaterials.length > 0 && (
        <div
          className="constructor-decor-color-picker"
          style={{ '--slides': decorMaterials.length }}
        >
          <p>Декор</p>
          <Swiper
            className="constructor-decor-color-picker-list"
            slideToClickedSlide={true}
            slidesPerView={'auto'}
            threshold={5}
            onSlideChange={onSlideChange}
          >
            {decorMaterials.map((decor, index) => (
              <SwiperSlide
                key={`${decor.id}-${index}`}
                onClick={() => onDecorColorChange(decor.attributes.color)}
                className={
                  getHexString(
                    constructorStore.materialColors['Material-4']
                  ).toUpperCase() === decor.attributes.color.toUpperCase()
                    ? 'active'
                    : ''
                }
              >
                <img
                  className="constructor-decor-color-picker--color"
                  src={getAssetUrl(
                    decor.attributes.preview.data.attributes.url
                  )}
                />
                <span>{decor.attributes.name}</span>
              </SwiperSlide>
            ))}
            {decorMaterials.length > 1 && (
              <>
                <CatalogItemPreviewButton
                  action="slidePrev"
                  disabled={isBeginning}
                />
                <CatalogItemPreviewButton action="slideNext" disabled={isEnd} />
              </>
            )}
          </Swiper>
        </div>
      )}

      <Copyright />

      <div className="constructor-wrapper" />
    </div>
  );
});

Constructor.propTypes = {
  decorMaterials: PropTypes.array,
  animationUrl: PropTypes.string,
  openAnimation: PropTypes.func,
  onClose: PropTypes.func,
  name: PropTypes.string,
};

export default Constructor;
