import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { getAssetUrl, onImageLoaded } from 'helpers';
import apiStore from 'store/apiStore';
import LazyLoad from 'react-lazy-load';

import geolocationIcon from 'assets/img/icon--geolocation.svg';
import phoneIcon from 'assets/img/icon--phone.svg';
import mailIcon from 'assets/img/icon--mail.svg';

const Factory = observer(() => {
  useEffect(() => {
    if (apiStore.contactsData) return;
    apiStore.loadContacts();
  }, []);

  return (
    <div className="factory-page">
      <h1>{apiStore.contactsData?.attributes.factory_name}</h1>
      <div className="factory-page-row">
        <LazyLoad className="factory-page-preview">
          <img
            src={getAssetUrl(
              apiStore.contactsData?.attributes.factory_preview.data.attributes
                .url
            )}
            onLoad={onImageLoaded}
            alt="preview"
          />
        </LazyLoad>
        <div className="factory-page-contacts">
          <h2>Контакты</h2>

          {apiStore.contactsData && (
            <Contact
              data={apiStore.contactsData?.attributes.factory_main_contacts}
            />
          )}

          {(apiStore.contactsData?.attributes.factory_contacts || []).map(
            (contact, index) => (
              <Contact data={contact} key={index} />
            )
          )}
        </div>
      </div>
    </div>
  );
});

const Contact = ({ data }) => {
  return (
    <div className="factory-page-contact">
      <p className="factory-page-contact-name">{data.name}</p>

      {data.address.length > 0 && (
        <div className="factory-page-contact-block">
          <img src={geolocationIcon} alt="geolocation-icon" />
          <div className="factory-page-contact-block-values">
            {data.address.map((address, index) => (
              <span key={index}>{address.address}</span>
            ))}
          </div>
        </div>
      )}

      {data.phone.length > 0 && (
        <div className="factory-page-contact-block">
          <img src={phoneIcon} alt="phone-icon" />
          <div className="factory-page-contact-block-values">
            {data.phone.map((phone, index) => (
              <span key={index}>
                <a href={`tel: ${phone.number}`}>{phone.number}</a>
                {`${phone.addon?.length ? `, ${phone.addon}` : ''}`}
              </span>
            ))}
          </div>
        </div>
      )}

      {data.email.length > 0 && (
        <div className="factory-page-contact-block">
          <img src={mailIcon} alt="mail-icon" />
          <div className="factory-page-contact-block-values">
            {data.email.map((email, index) => (
              <span key={index}>{email.email}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Contact.propTypes = {
  data: PropTypes.object,
};

export default Factory;
