import * as THREE from 'three';

import preview from 'assets/textures/leather/preview.jpg';
import roughness from 'assets/textures/leather/roughness.png';
import normal from 'assets/textures/leather/normal.png';

export default {
  preview,
  name: 'Кожа',
  material: {
    constructor: THREE.MeshPhysicalMaterial,
    specularIntensity: 0.5,
    roughness: 0.4,
    metalness: 0,
    textures: [
      {
        key: 'roughnessMap',
        url: roughness,
        repeat: [25, 25],
      },
      {
        key: 'normalMap',
        url: normal,
        repeat: [25, 25],
      },
    ],
  },
};
