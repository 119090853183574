import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiStore from 'store/apiStore';

const Home = observer(() => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    username: 'dealer-1',
    password: 'Passw0rd',
  });

  const onChange = ({ target }) => {
    const { value, name } = target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const request = {
      identifier: form.username,
      password: form.password,
    };

    const { jwt, user } = await apiStore.post('auth/local', request);

    apiStore.setJwt(jwt);
    apiStore.setUser(user);

    navigate('/admin');
  };

  useEffect(() => {
    apiStore.setJwt(null);
    localStorage.removeItem('auth-jwt');
  }, []);

  return (
    <section className="login-page">
      <form className="login-form" onSubmit={onSubmit}>
        <h2>Авторизация</h2>
        <input
          placeholder="Логин"
          type="text"
          name="username"
          value={form.username}
          onChange={onChange}
        />
        <input
          placeholder="Пароль"
          type="password"
          name="password"
          value={form.password}
          onChange={onChange}
        />
        <button type="submit">Войти</button>
      </form>
    </section>
  );
});

export default Home;
