import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getAssetUrlBySize, onImageLoaded } from 'helpers';
import LazyLoad from 'react-lazy-load';

import CatalogItemDimensions from 'Components/CatalogItemDimensions';

const RecommendationsItem = ({ data }) => {
  const filteredMedia =
    data.attributes.media.data?.filter((media) => {
      return media.attributes.mime.includes('image');
    }) || [];

  const mediaUrl = getAssetUrlBySize(filteredMedia[0], 'small');

  return (
    <Link to={`/catalog/${data.id}`} className="recommendations-item">
      <LazyLoad className="recommendations-item-preview">
        <img src={mediaUrl} onLoad={onImageLoaded} />
      </LazyLoad>
      <div className="recommendations-item-text">
        <h4>{data.attributes.name}</h4>
        <p>{data.attributes.model}</p>
        <CatalogItemDimensions dimensions={data.attributes?.dimensions} />
      </div>
    </Link>
  );
};

RecommendationsItem.propTypes = {
  data: PropTypes.object,
};

export default RecommendationsItem;
