import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import Logo from 'Components/Logo';

import documentIcon from 'assets/img/icon--document.svg';
import houseIcon from 'assets/img/icon--house-1.svg';
import chairIcon from 'assets/img/icon--chair.svg';
import phoneIcon from 'assets/img/icon--phone.svg';

const InfoPagesWrapper = observer(({ children }) => {
  const links = [
    {
      icon: { url: chairIcon, alt: 'chair-icon' },
      url: '/about',
      name: 'О фабрике',
    },
    {
      icon: { url: documentIcon, alt: 'document-icon' },
      url: '/documents',
      name: 'Документы',
    },
    {
      icon: { url: phoneIcon, alt: 'phone-icon' },
      url: '/contacts',
      name: 'Контакты',
    },
  ];

  return (
    <section className="info-page-wrapper">
      <Logo white={false} />

      <div className="info-page-wrapper-navigation">
        {links.map((link, index) => (
          <Link
            className={location.pathname === link.url ? 'active' : ''}
            to={link.url}
            key={index}
          >
            <img src={link.icon.url} alt={link.icon.alt} />
            <span>{link.name}</span>
          </Link>
        ))}
      </div>

      <Link to="/" className="info-page-wrapper-close">
        <div className="info-page-wrapper-close-icon">
          <img src={houseIcon} />
        </div>
      </Link>

      {children}
    </section>
  );
});

export default InfoPagesWrapper;
