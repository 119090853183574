import { makeAutoObservable } from 'mobx';
import ConstructorEnvironment from './constructorEnvironment';
import ConstructorControls from './constructorControls';
import MaterialLoader from './materialLoader';
import HDRLoader from './hdrLoader';
import loadingStore from 'store/loadingStore';
import deviceStore from 'store/deviceStore';

import HDREnv from 'assets/hdr/environment.hdr';
import shadowImage from 'assets/img/shadow.png';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';

export const initialColors = {
  'Material-1': new THREE.Color(0xd4af93),
  'Material-2': new THREE.Color(0x0a0a0a),
  'Material-3': new THREE.Color(0xd4af93),
  'Material-4': new THREE.Color(0x100a0a),
  'Material-5': new THREE.Color(0xd4af93),
  'Material-6': new THREE.Color(0xffffff),
  'Material-7': new THREE.Color(0x0a0a0a),
  'Material-8': new THREE.Color(0xa3a3a3),
  'Material-9': new THREE.Color(0xd7b790),
  'Material-10': new THREE.Color(0x000000),
  'Material-11': new THREE.Color(0x3c3c3c),
};

class ConstructorStore {
  materialLoader = new MaterialLoader();

  materialColors = { ...initialColors };

  currentMaterial = { name: '' };

  hdrLoader = new HDRLoader();

  currentLayer = 'Material-1';

  environmentVisible = false;

  clock = new THREE.Clock();

  environment;

  controls;

  renderer;

  camera;

  scene;

  model;

  grid;

  hdr;

  constructor() {
    makeAutoObservable(this);

    this.createRenderer();
    this.createScene();
    this.createCamera();
    this.createControls();
    this.createEnvironment();
    this.createLights();

    this.updateWindowSize(0, 0);

    this.animate();
  }

  // init

  createScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xffffff);

    return this;
  }

  createLights() {
    const point = new THREE.PointLight(0xffffff, 2);
    point.position.set(-1, 4, 0);

    this.scene.add(point);
  }

  createGrid(url = shadowImage) {
    if (this.grid) {
      this.grid.removeFromParent();
    }

    return new THREE.TextureLoader().loadAsync(url).then((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);

      const geometry = new THREE.PlaneGeometry(5, 5);
      const material = new THREE.MeshBasicMaterial({
        transparent: true,
        map: texture,
      });

      this.grid = new THREE.Mesh(geometry, material);
      this.grid.rotation.set(-Math.PI / 2, 0, 0);

      this.scene.add(this.grid);
    });
  }

  createCamera() {
    const aspect = 1920 / 1080;
    const near = 0.1;
    const far = 2000;
    const fov = 30;

    this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this.camera.position.set(0, 0, 0);

    return this;
  }

  createControls() {
    this.controls = new ConstructorControls(this.camera, this.renderer);
    this.scene.rotation.y = this.controls.envAngle;

    return this;
  }

  createEnvironment() {
    this.environment = new ConstructorEnvironment();
    this.scene.add(this.environment);

    return this;
  }

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true,
      antialias: true,
      alpha: true,
    });
    this.renderer.toneMappingExposure = 1;
    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.physicallyCorrectLights = true;
    this.renderer.autoClear = true;

    return this;
  }

  updateWindowSize(width, height) {
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;

    if (this.environmentVisible) {
      this.setEnvironmentCamera();
    }

    this.render();

    return this;
  }

  animate() {
    const delta = this.clock.getDelta();
    const hasControlsUpdated = this.controls.update(delta);

    requestAnimationFrame(this.animate.bind(this));

    if (hasControlsUpdated) {
      this.render();
    }
  }

  render() {
    this.camera.updateProjectionMatrix();
    this.renderer.render(this.scene, this.camera);

    return this;
  }

  // model

  async loadModel(url) {
    const onProgress = ({ total, loaded }) => {
      loadingStore.setLoadingData({
        title: 'Загружаю модель...',
        loaded,
        total,
      });
    };

    const onLoad = ({ scene }) => {
      const object = scene.children[0];
      object.children.forEach((child) => {
        const { name } = child.material;
        const newMaterial = new THREE.MeshStandardMaterial();
        newMaterial.name = name;
        child.material = newMaterial;
      });

      this.removeModel();

      this.scene.add(object);
      this.model = object;

      this.setControlsTarget(object);

      return this.model;
    };

    return await new GLTFLoader().loadAsync(url, onProgress).then(onLoad);
  }

  removeModel() {
    this.model?.removeFromParent();
    this.model = null;
  }

  setControlsTarget(object) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);
    const size = boundingBox.getSize(new THREE.Vector3());
    const center = boundingBox.getCenter(new THREE.Vector3());
    const maxDimension = Math.max(size.x, size.y, size.z);

    object.size = size;
    object.center = center;

    const distance =
      this.controls.getDistanceToFitBox(size.x, size.y, size.z) + 3 || 0;

    this.controls.maxDistance = Math.max(
      distance * 2,
      this.controls.environmentDistance
    );
    this.controls.minDistance = maxDimension / 1.5;

    this.controls.normalizeRotations();
    this.controls.rotatePolarTo(Math.PI / 2.125);
    this.controls.rotateAzimuthTo(this.controls.envAngle);
    this.controls.moveTo(center.x, center.y, center.z);
    this.controls.dollyTo(this.controls.environmentDistance);

    return this;
  }

  async openAR() {
    const isIos = deviceStore.device.vendor === 'Apple';

    this.render();

    const createLink = (href) => {
      const link = document.createElement('a');
      link.appendChild(document.createElement('img'));
      link.href = href;

      if (isIos) {
        link.download = 'ar-model.usdz';
        link.rel = 'ar';
      }

      link.click();
    };

    const getBlobFromArrayBuffer = (arraybuffer) => {
      return new Blob([arraybuffer], { type: 'application/octet-stream' });
    };

    if (isIos) {
      const exporter = new USDZExporter();
      const arraybuffer = await exporter.parse(this.model);
      const blob = getBlobFromArrayBuffer(arraybuffer);
      const modelUrl = URL.createObjectURL(blob);

      return createLink(modelUrl);
    }

    const exporter = new GLTFExporter();
    const arraybuffer = await exporter.parseAsync(this.model, { binary: true });
    const blob = getBlobFromArrayBuffer(arraybuffer);
    const modelUrl = URL.createObjectURL(blob);

    return createLink(modelUrl);
  }

  // materials

  getMeshByName(name) {
    return this.model?.children.find((child) => child.material.name === name);
  }

  setMaterialColor(value, materialName = this.currentLayer) {
    const mesh = this.getMeshByName(materialName);

    if (mesh) {
      mesh.material.color = value;
    }

    this.materialColors[materialName] = value;
    this.render();

    return this;
  }

  async applyMaterial(name, materialName) {
    const cachedMaterial = THREE.Cache.get(`material-${name}`);

    const onLoad = (material) => {
      const mesh = this.getMeshByName(materialName);

      if (!mesh) return;

      const { name } = mesh.material;
      const newMaterial = material.clone();
      newMaterial.color = this.materialColors[materialName];
      newMaterial.materialName = material.materialName;
      newMaterial.name = name;

      mesh.material = newMaterial;

      return material;
    };

    if (cachedMaterial) {
      return onLoad(cachedMaterial);
    }

    return this.materialLoader.load(name).then(onLoad);
  }

  setCurrentMaterial(material) {
    this.currentMaterial = material;
  }

  setCurrentLayer(name) {
    this.currentLayer = name;

    const mesh = this.getMeshByName(name);
    this.setCurrentMaterial(mesh.material);
  }

  // environment

  setEnvironmentCamera() {
    this.controls.normalizeRotations();
    this.controls.rotatePolarTo(Math.PI / 2.125);
    this.controls.rotateAzimuthTo(this.controls.envAngle);
    this.controls.maxDistance = this.controls.environmentDistance;
    this.controls.moveTo(0, 0.45, 0);
    this.controls.dollyTo(this.controls.environmentDistance);
  }

  setEnvironmentVisibility(value) {
    this.environmentVisible = value;

    this.controls.stopInstruction();

    if (!this.environmentVisible) {
      this.environment.hide();
      this.controls.enable();

      if (this.model) {
        this.setControlsTarget(this.model);
      }

      this.render();

      return;
    }

    this.setEnvironmentCamera();
    this.environment.show(this.model);
    this.controls.disable();

    this.render();
  }

  async loadHDR() {
    const onProgress = ({ total, loaded }) => {
      loadingStore.setLoadingData({
        title: 'Настраиваю освещение...',
        loaded,
        total,
      });
    };

    const texture = await this.hdrLoader.loadHDR(HDREnv, onProgress);

    this.hdr = texture;
    this.scene.environment = this.hdr;

    return texture;
  }
}

export default new ConstructorStore();
