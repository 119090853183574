import qs from 'qs';
import { makeAutoObservable } from 'mobx';
import { API_TOKEN, API_URL } from 'config';
import jwt_decode from 'jwt-decode';

const searchParams = new URLSearchParams(location.search);

class ApiStore {
  loading = false;

  contactsData = null;

  salonsData = [];

  warehousesData = [];

  fabricsData = [];

  suppliersData = [];

  declarationsData = [];

  advertisementsData = [];

  instructionsData = [];

  furniture = [];

  categories = [];

  currentPage = Number(searchParams.get('page') || 1);

  totalPagesCount = 0;

  chosenCategories = [];

  filtersLoaded = false;

  filters = {
    glb: searchParams.get('glb') === '1',
    decor: searchParams.get('decor') === '1',
    storage: searchParams.get('storage') === '1',
    high_legs: searchParams.get('high_legs') === '1',
    metal_frame: searchParams.get('metal_frame') === '1',
    name: searchParams.get('name') || '',
    sitting_places: [],
    mechanism: [],
    comfort: [],
  };

  recommendations = {
    hits: [],
    new: [],
  };

  banners = [];

  jwt = localStorage.getItem('auth-jwt');

  user = null;

  constructor() {
    makeAutoObservable(this);

    this.loadCategories().then(() => {
      if (this.chosenCategories.length) return;
      this.setChosenCategories(this.categories.map(({ id }) => id));
    });

    this.loadHits().then(() => this.loadNew());

    this.loadBanners();

    if (this.jwt) {
      this.loadUser().catch(() => {
        this.setJwt(null);
        localStorage.removeItem('auth-jwt');
      });
    }
  }

  setCurrentPage(value) {
    this.currentPage = value;
  }

  setTotalPagesCount(value) {
    this.totalPagesCount = value;
  }

  setLoadingState(value) {
    this.loading = value;
  }

  setRecommendations(value) {
    this.recommendations = value;
  }

  setBanners(value) {
    this.banners = value;
  }

  setContactsData(data) {
    this.contactsData = data;
  }

  setSalonsData(data) {
    this.salonsData = data;
  }

  setWarehousesData(data) {
    this.warehousesData = data;
  }

  setFabricsData(data) {
    this.fabricsData = data;
  }

  setSuppliersData(data) {
    this.suppliersData = data;
  }

  setDeclarationsData(data) {
    this.declarationsData = data;
  }

  setAdvertisementsData(data) {
    this.advertisementsData = data;
  }

  setInstructionsData(data) {
    this.instructionsData = data;
  }

  setFiltersData(key, data) {
    this.filters[key] = data;
  }

  setFiltersLoadedState(value) {
    this.filtersLoaded = value;
  }

  setOptionData(groupKey, index, checked) {
    const group = this.filters[groupKey];
    group[index].attributes.checked = checked;
    this.filters = { ...this.filters, [groupKey]: [...group] };
  }

  setBooleanOptionData(key, checked) {
    this.filters = { ...this.filters, [key]: checked };
  }

  setStringOptionData(key, value) {
    this.filters = { ...this.filters, [key]: value };
  }

  setFurnitureData(data) {
    this.furniture = data;
  }

  setCategories(data) {
    this.categories = data;
  }

  setChosenCategories(ids) {
    this.chosenCategories = ids;
  }

  setJwt(jwt) {
    localStorage.setItem('auth-jwt', jwt);
    this.jwt = jwt;
  }

  setUser(user) {
    this.user = user;
  }

  updateSearchParams() {
    const reducer = (acc, [key, value]) => {
      if (typeof value === 'boolean') {
        if (!value) return acc;
        return { ...acc, [key]: '1' };
      }

      if (typeof value === 'string') {
        if (!value.length) return acc;
        return { ...acc, [key]: value };
      }

      const ids = value
        .filter(({ attributes }) => attributes.checked)
        .map(({ id }) => id);

      if (!ids.length) return acc;

      return { ...acc, [key]: ids.join(',') };
    };

    const params = {
      ...Object.entries(this.filters).reduce(reducer, {}),
      categories: [...this.chosenCategories].join(','),
      page: this.currentPage,
    };

    const url = new URL(location.origin);
    url.pathname = location.pathname;
    url.search = qs.stringify(params);

    window.history.pushState(null, '', url);
  }

  getQuery(filters, populate = '*', pagination = {}, sort = {}) {
    const query = qs.stringify(
      {
        filters,
        populate,
        pagination,
        sort,
      },
      {
        encodeValuesOnly: true,
      }
    );

    return query;
  }

  async fetch(url, token = API_TOKEN) {
    const headers = { Authorization: `Bearer ${token}` };
    return await fetch(url, { headers });
  }

  async get(path, query, token) {
    const url = `${API_URL}/${path}?${query}`;
    const response = await this.fetch(url, token);
    return await response.json();
  }

  async post(path, data) {
    const url = `${API_URL}/${path}`;
    const headers = { 'Content-Type': 'application/json' };

    if (this.jwt) {
      headers.Authorization = `Bearer ${this.jwt}`;
    }

    const response = await fetch(url, {
      body: JSON.stringify(data),
      method: 'post',
      headers,
    });

    return await response.json();
  }

  async put(path, data) {
    const url = `${API_URL}/${path}`;
    const headers = { 'Content-Type': 'application/json' };

    if (this.jwt) {
      headers.Authorization = `Bearer ${this.jwt}`;
    }

    const response = await fetch(url, {
      body: JSON.stringify(data),
      method: 'put',
      headers,
    });

    return await response.json();
  }

  async delete(path) {
    const url = `${API_URL}/${path}`;
    const headers = { 'Content-Type': 'application/json' };

    if (this.jwt) {
      headers.Authorization = `Bearer ${this.jwt}`;
    }

    const response = await fetch(url, {
      method: 'delete',
      headers,
    });

    return await response.json();
  }

  async loadCategories() {
    const query = `${this.getQuery({}, '*')}&sort=id`;
    const { data } = await this.get('categories', query);
    return this.setCategories(data);
  }

  async loadFilteredFurnitures() {
    const reducer = (acc, [key, value]) => {
      if (typeof value === 'boolean') {
        if (!value) return acc;
        return [...acc, { [key]: { $eq: value } }];
      }

      if (typeof value === 'string') {
        if (!value.length) return acc;
        return [...acc, { [key]: { $containsi: value } }];
      }

      const ids = value
        .filter(({ attributes }) => attributes.checked)
        .map(({ id }) => id);

      if (!ids.length) return acc;

      return [...acc, { [key]: { id: { $in: ids } } }];
    };

    const defaultFilters = {
      $and: [
        { categories: { id: { $in: [...this.chosenCategories] } } },
        ...Object.entries(this.filters).reduce(reducer, []),
      ],
    };

    if (this.chosenCategories.length < 1) {
      defaultFilters.$and[0].categories.id.$in.push(-1);
    }

    const populate = {
      categories: { populate: '*' },
      dimensions: { populate: '*' },
      media: { populate: '*' },
    };

    const pagination = {
      pageSize: 10,
      page: this.currentPage,
    };

    const sort = ['name:asc'];

    const query = this.getQuery(defaultFilters, populate, pagination, sort);

    this.setLoadingState(true);

    const { data, meta } = await this.get('furnitures', query);

    this.setTotalPagesCount(meta.pagination.pageCount);
    this.setLoadingState(false);

    return this.setFurnitureData(data || []);
  }

  async loadFurnitureById(id) {
    const populate = {
      decor_materials: { populate: '*' },
      instruction: { populate: '*' },
      categories: { populate: '*' },
      dimensions: { populate: '*' },
      advantages: { populate: '*' },
      glb_model: { populate: '*' },
      animation: { populate: '*' },
      mechanism: { populate: '*' },
      shadow: { populate: '*' },
      media: { populate: '*' },
    };

    const query = this.getQuery({}, populate);

    const { data } = await this.get(`furnitures/${id}`, query);
    return data;
  }

  async loadHits() {
    const filters = { hit: { $eq: true } };

    const populate = {
      dimensions: { populate: '*' },
      media: { populate: '*' },
    };

    const sort = ['name:asc'];

    const query = this.getQuery(filters, populate, {}, sort);

    const { data } = await this.get('furnitures', query);
    this.setRecommendations({ ...this.recommendations, hits: data });
    return data;
  }

  async loadNew() {
    const filters = { new: { $eq: true } };

    const populate = {
      dimensions: { populate: '*' },
      media: { populate: '*' },
    };

    const sort = ['name:asc'];

    const query = this.getQuery(filters, populate, {}, sort);

    const { data } = await this.get('furnitures', query);
    this.setRecommendations({ ...this.recommendations, new: data });
    return data;
  }

  async loadBanners() {
    const populate = { media: { populate: '*' } };

    const query = this.getQuery({}, populate);

    const { data } = await this.get('banner', query);
    this.setBanners(data?.attributes.media || []);
    return data;
  }

  async loadContacts() {
    const populate = {
      factory_main_contacts: { populate: '*' },
      factory_contacts: { populate: '*' },
      factory_preview: { populate: '*' },
      paragraphs: { populate: '*' },
      numbers: { populate: '*' },
      video: { populate: '*' },
    };

    const query = this.getQuery({}, populate);

    const { data } = await this.get('contact', query);
    this.setContactsData(data);
    return data;
  }

  async loadSalons() {
    const populate = { contacts: { populate: '*' } };

    const sort = ['id:asc'];

    const query = this.getQuery({}, populate, {}, sort);

    const { data } = await this.get('salons', query);
    this.setSalonsData(data);
    return data;
  }

  async loadWarehouses() {
    const populate = { contacts: { populate: '*' } };

    const sort = ['id:asc'];

    const query = this.getQuery({}, populate, {}, sort);

    const { data } = await this.get('warehouses', query);
    this.setWarehousesData(data);
    return data;
  }

  async loadFabrics() {
    const populate = { files: { populate: '*' } };

    const query = this.getQuery({}, populate);

    const { data } = await this.get('fabric', query);
    this.setFabricsData(data.attributes.files.data);
    return data;
  }

  async loadSuppliers() {
    const populate = { logo: { populate: '*' } };

    const query = this.getQuery({}, populate);

    const { data } = await this.get('suppliers', query);
    this.setSuppliersData(data);
    return data;
  }

  async loadDeclarations() {
    const populate = { files: { populate: '*' } };

    const query = this.getQuery({}, populate);

    const { data } = await this.get('declaration', query);
    this.setDeclarationsData(data.attributes.files.data);
    return data;
  }

  async loadAdvertisements() {
    const populate = { advertisements: { populate: '*' } };

    const query = this.getQuery({}, populate);

    const { data } = await this.get('advertisement', query);
    const result = data.attributes.advertisements.map((item) => {
      return {
        attributes: {
          ...item.file.data.attributes,
          previewUrl: item.preview.data.attributes.url,
          name: item.name,
          id: item.id,
        },
      };
    });
    this.setAdvertisementsData(result);
    return data;
  }

  async loadInstructions() {
    let currentPage = 1;

    const populate = { instruction: { populate: '*' } };

    const sort = ['name:asc'];

    const load = async (page) => {
      const pagination = { pageSize: 100, page };

      const query = this.getQuery({}, populate, pagination, sort);

      return await this.get('furnitures', query);
    };

    const furnitures = [];

    const response = await load(currentPage);
    furnitures.push(...response.data);

    while (currentPage < response.meta.pagination.pageCount) {
      currentPage += 1;
      const { data } = await load(currentPage);
      furnitures.push(...data);
    }

    const reducer = (acc, furniture) => {
      if (!furniture.attributes.instruction.data) return acc;

      acc.push(furniture.attributes.instruction.data);

      return acc;
    };

    const result = furnitures.reduce(reducer, []);
    this.setInstructionsData(result);
    return result;
  }

  async loadUser() {
    const { id } = jwt_decode(this.jwt);
    const user = await this.get(`users/${id}`, '', this.jwt);

    if (user.error) {
      throw new Error('Auth error');
    }

    this.setUser(user);
  }
}

export default new ApiStore();
