import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

class HDRLoader extends RGBELoader {
  async loadHDR(url, onProgress = () => {}) {
    const texture = await this.loadAsync(url, onProgress);

    texture.mapping = THREE.EquirectangularReflectionMapping;

    return texture;
  }
}

export default HDRLoader;
