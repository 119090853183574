import * as THREE from 'three';
import CameraControls from 'camera-controls';

CameraControls.install({ THREE });

class ConstructorControls extends CameraControls {
  mousePosition = { x: 0, y: 0 };

  environmentDistance = 9;

  envAngle = 4.53786;

  instructionTimeout;

  holding = false;

  renderer;

  camera;

  constructor(camera, renderer) {
    super(camera, renderer.domElement);

    this.renderer = renderer;
    this.camera = camera;

    this.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
    this.touches.three = CameraControls.ACTION.NONE;
    this.mouseButtons.right = CameraControls.ACTION.NONE;
    this.mouseButtons.middle = CameraControls.ACTION.NONE;
    this.maxDistance = this.environmentDistance;
    this.maxPolarAngle = Math.PI / 2;
    this.minPolarAngle = 0.35;
    this.restThreshold = 0.03;

    this.rotatePolarTo(Math.PI / 2.25);
    this.rotateAzimuthTo(this.envAngle);
    this.dollyTo(this.environmentDistance);

    this.addListeners();
  }

  enable() {
    this.enabled = true;
    this.renderer.domElement.style.cursor = 'grab';
  }

  disable() {
    this.enabled = false;
    this.renderer.domElement.style.cursor = 'default';
  }

  onControlStart() {
    this.holding = true;
    this.renderer.domElement.style.cursor = 'grabbing';
    this.stopInstruction();
  }

  onControlEnd() {
    this.holding = false;
    this.renderer.domElement.style.cursor = 'grab';
  }

  addListeners() {
    this.addEventListener('controlstart', this.onControlStart.bind(this));
    this.addEventListener('controlend', this.onControlEnd.bind(this));

    this.mousePosition = {
      x: this.renderer.domElement.innerWidth / 2,
      y: this.renderer.domElement.innerHeight / 2,
    };

    this.renderer.domElement.style.cursor = 'grab';
  }

  startInstruction(delay = 2000) {
    this.interacted = false;
    this.instructionTimeout = setTimeout(() => {
      this.startRotateAnimation();
    }, delay);
  }

  stopInstruction() {
    this.interacted = true;
    clearTimeout(this.instructionTimeout);
    this.instructionTimeout = null;
  }

  async startRotateAnimation() {
    const angle = 0.3;
    if (this.interacted) return this.stopInstruction();
    await this.rotateAzimuthTo(this.envAngle + angle, true);
    if (this.interacted) return this.stopInstruction();
    await this.rotateAzimuthTo(this.envAngle - angle, true);
    if (this.interacted) return this.stopInstruction();
    await this.rotateAzimuthTo(this.envAngle, true);
    if (this.interacted) return this.stopInstruction();
    this.startInstruction(5000);
  }
}

export default ConstructorControls;
